import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const StyledImage = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(0, 4, 4, 0),
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: '100%',
  marginInlineStart: theme.spacing(2),
  '&, &:hover': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));
