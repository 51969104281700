//TODO: Custom SVG Icons should not be served as URLs since it results in adding extra requests to the server. Instead, they should be imported as components from our package.
import { StyledLogo } from '@components/logo/styles';
import { StyledGradientText, StyledLink } from '@components/pages/home/styles';
import {
  StyledCenteredContent,
  StyledViewportHeightStack,
} from '@components/pages/styles';
import SearchForm from '@components/search-form';
import { useTranslation } from '@desygner/ui-common-translation';
import useCreateOneThread from '@hooks/useCreateOneThread';
import useGeolocation from '@hooks/useGeoLocation';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import paths from '@router/paths';
import { Link, useNavigate } from 'react-router-dom';

type FormType = {
  name: string;
  advanced: boolean; //? Genius Search
};

export default function Home() {
  const { t } = useTranslation();

  const navigateTo = useNavigate();
  const theme = useTheme();

  //? get user location
  useGeolocation();

  const { mutateAsync: createOneThreadAsync } = useCreateOneThread();

  async function onSubmit({ name }: FormType) {
    const { data } = await createOneThreadAsync({ type: 'search', name });

    navigateTo(`${paths.search.pathname}?t=${data.id}`, {
      state: { prompt: name, isUserRedirected: true },
    });
  }

  return (
    <StyledViewportHeightStack>
      <StyledCenteredContent gap={8}>
        <StyledLink
          component={Link}
          to={paths.enterprise.pathname}
          target="_blank"
          disableRipple
          disableTouchRipple
          variant="outlined"
          endIcon={<KeyboardArrowRightRoundedIcon />}
          startIcon={
            <StyledLogo
              src="https://assets.knowz.com/assets/svg/knowz---solid.svg"
              alt="Knowz Solid Logo"
              width={47}
              height={14}
            />
          }
        >
          {t('page.home.hero.overlineButton', {
            defaultValue: 'enterprise knowledge',
          })}
        </StyledLink>
        <Stack gap={2.5}>
          <Typography
            component="h1"
            variant="display1"
            sx={{ textAlign: 'center' }}
          >
            <StyledGradientText
              component="span"
              sx={{
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.gradients('to top').neutral
                    : theme.palette.gradients('to top').black,
              }}
            >
              {t('page.home.hero.title.segment1', {
                defaultValue: 'Unleashing',
              })}
            </StyledGradientText>
            &nbsp;
            <StyledGradientText
              component="span"
              sx={{
                background: theme.palette.gradients('to left').primary,
              }}
            >
              {t('page.home.hero.title.segment2', {
                defaultValue: 'AI Insights',
              })}
            </StyledGradientText>
          </Typography>
          <Typography variant="body1" align="center">
            {t('page.home.hero.subtitle', {
              defaultValue: 'Search no more, Knowz better',
            })}
          </Typography>
        </Stack>
        <SearchForm
          onSubmit={onSubmit}
          sx={{
            width: theme.spacing(139),
            minWidth: theme.spacing(10),
            [theme.breakpoints.down('md')]: {
              width: theme.spacing(100),
            },
            [theme.breakpoints.down('sm')]: {
              width: theme.spacing(80),
            },
          }}
        />
      </StyledCenteredContent>
    </StyledViewportHeightStack>
  );
}
