import getDataWithPagination from '@components/pages/history/utils/getDataWithPagination';
import { MinimalFolderType } from '@components/pages/knowledge-base/admin/types';
import useWorkspace from '@hooks/useWorkspace';
import { sources } from '@lib/agent';
import { PaginationType } from '@shared-types/pagination';
import { useQuery } from '@tanstack/react-query';

type GetSourcesDataByFolderIdLazilyType = PaginationType & {
  folderId: MinimalFolderType['folderId'];
};

export default function useGetSourcesDataByFolderId({
  folderId,
  limit,
  page,
}: GetSourcesDataByFolderIdLazilyType) {
  const { getNamespacedQueryKey } = useWorkspace();
  const {
    data: sourcesData,
    isLoading: isSourcesDataLoading,
    isError: isSourcesDataError,
    isPending: isSourcesDataPending,
  } = useQuery({
    queryKey: getNamespacedQueryKey('sources', `page: ${page}`, folderId),
    queryFn: async () => {
      try {
        const { data, headers } = await sources.getAll({
          folder: folderId,
          page,
          limit,
        });

        const totalCount = headers['xTotalCount'] as string;

        const dataWithPagination = getDataWithPagination({
          data,
          totalCount,
          pageParam: page,
          limit,
        });

        return dataWithPagination;
      } catch (error: unknown) {
        throw new Error(`An error occurred while fetching sources: ${error}`);
      }
    },
  });

  return {
    sourcesData,
    isSourcesDataLoading,
    isSourcesDataError,
    isSourcesDataPending,
  };
}
