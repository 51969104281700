import { styled } from '@mui/material';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)(() => ({
  '.uppy-FileInput-btn': {
    display: 'none',
  },
})) as typeof Button;

export default StyledButton;
