// import React, {
//   createContext,
//   PropsWithChildren,
//   useContext,
//   useState,
//   useCallback,
// } from 'react';

// interface SpeakingContextType {
//   currentSpeakingId: string | null;
//   setCurrentSpeakingId: (id: string | null) => void;
//   isSpeaking: Record<string, boolean>;
//   setIsSpeaking: (id: string, isSpeaking: boolean) => void;
//   disabled: Record<string, boolean>;
//   setDisabled: (id: string, disabled: boolean) => void;
// }

// const SpeakingContext = createContext<SpeakingContextType | undefined>(
//   undefined,
// );

// export default function SpeakingProvider({ children }: PropsWithChildren) {
//   const [currentSpeakingId, setCurrentSpeakingId] = useState<string | null>(
//     null,
//   );
//   const [isSpeaking, setIsSpeakingState] = useState<Record<string, boolean>>(
//     {},
//   );
//   const [disabled, setDisabledState] = useState<Record<string, boolean>>({});

//   const setIsSpeaking = useCallback(function setIsAudioPlaying(
//     id: string,
//     speaking: boolean,
//   ) {
//     console.log('setIsSpeaking 👹', id, speaking);
//     setIsSpeakingState((prev) => ({ ...prev, [id]: speaking }));
//   },
//   []);

//   const setDisabled = useCallback(function setButtonDisableState(
//     id: string,
//     disabled: boolean,
//   ) {
//     console.log('setDisabled 👹', id, disabled);
//     setDisabledState((prev) => ({ ...prev, [id]: disabled }));
//   },
//   []);

//   return (
//     <SpeakingContext.Provider
//       value={{
//         currentSpeakingId,
//         setCurrentSpeakingId,
//         isSpeaking,
//         setIsSpeaking,
//         disabled,
//         setDisabled,
//       }}
//     >
//       {children}
//     </SpeakingContext.Provider>
//   );
// }

// export const useSpeakingContext = () => {
//   const context = useContext(SpeakingContext);
//   if (!context) {
//     throw new Error(
//       'useSpeakingContext must be used within a SpeakingProvider',
//     );
//   }
//   return context;
// };

import React, { createContext, useContext, useState } from 'react';

interface SpeakingContextType {
  currentSpeakingId: string | null;
  setCurrentSpeakingId: (id: string | null) => void;
  // isSpeaking: Record<string, boolean>;
  // setIsSpeaking: (id: string, isSpeaking: boolean) => void;
  // disabled: Record<string, boolean>;
  // setDisabled: (id: string, disabled: boolean) => void;
}

const SpeakingContext = createContext<SpeakingContextType | null>(null);

export default function AudioProvider({ children }: React.PropsWithChildren) {
  const [currentSpeakingId, setCurrentSpeakingId] = useState<string | null>(
    null,
  );

  return (
    <SpeakingContext.Provider
      value={{ currentSpeakingId, setCurrentSpeakingId }}
    >
      {children}
    </SpeakingContext.Provider>
  );
}

export const useSpeakingContext = () => {
  const context = useContext(SpeakingContext);
  if (!context) {
    throw new Error('useSpeakingContext must be used within an AudioProvider');
  }
  return context;
};
