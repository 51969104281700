import useGetThreadIdFromQueryParam from '@hooks/useGetThreadFromQueryParam';
import { threads } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

export default function useGetOneThread() {
  const { threadId: threadIdQueryParam } = useGetThreadIdFromQueryParam();

  const { data: oneThreadData, isLoading: isOneThreadDataLoading } = useQuery({
    queryKey: ['thread', threadIdQueryParam],
    queryFn: async () => {
      try {
        const { data: threadData } = await threads.getThreadById(
          threadIdQueryParam!,
        );

        return threadData;
      } catch (error) {
        console.error(error);
      }
    },
  });

  return {
    oneThreadData,
    isOneThreadDataLoading,
  };
}
