import StyledStack from '@components/pages/knowledge-base/components/header/styles';
import KNOWLEDGE_BASE_CONFIG from '@components/pages/knowledge-base/config';
import useGetFolderPathFromQueryParam from '@components/pages/knowledge-base/hooks/useGetFolderPathFromQueryParam';
import useGetFoldersDataById from '@components/pages/knowledge-base/hooks/useGetFoldersDataById';
import useGetSourcesDataByFolderId from '@components/pages/knowledge-base/hooks/useGetSourcesDataByFolderId';
import useKnowledgeBaseData from '@components/pages/knowledge-base/hooks/useKnowledgeBaseData';
import { useTranslation } from '@desygner/ui-common-translation';
import useGetFolderDataByPath from '@hooks/useGetFolderDataByPath';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';
import AddIcon from '@mui/icons-material/Add';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { Skeleton } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

export default function Header() {
  const path = useGetFolderPathFromQueryParam();

  const { getQueryParamByKey } = useQueryParams();

  const { handleToggleAddKnowledgeModal, handleToggleCreateFolderModal } =
    useKnowledgeBaseData();

  const { userRole } = useWorkspace();

  const { folderData, isFolderDataLoading, isFolderDataPending } =
    useGetFolderDataByPath({ path });

  const { foldersData, isFoldersDataLoading } = useGetFoldersDataById({
    folderId: folderData?.id as number | null,
  });

  const { sourcesData, isSourcesDataLoading } = useGetSourcesDataByFolderId({
    folderId: folderData?.id as number | null,
    limit: KNOWLEDGE_BASE_CONFIG.PAGINATION.LIMIT,
    page: parseInt(getQueryParamByKey('page', '1')),
  });

  const resources = useMemo(
    () => [...(foldersData || []), ...(sourcesData?.data || [])],
    [foldersData, sourcesData],
  );

  const areResourcesLoading =
    isFolderDataLoading || isFoldersDataLoading || isSourcesDataLoading;

  const areResourcesEmpty = resources.length === 0;

  const { t } = useTranslation();

  const isItRootFolder = folderData === null;

  return (
    <StyledStack>
      <Stack gap={2}>
        {isFolderDataPending ? (
          <Skeleton width={150} height={50} />
        ) : isItRootFolder ? (
          <Typography variant="h3">
            {t('page.knowledgeBase.admin.header.title', {
              defaultValue: 'Knowledge Base',
            })}
          </Typography>
        ) : (
          <Typography variant="h3">{folderData && folderData.name}</Typography>
        )}
      </Stack>
      <Stack direction="row" gap={3}>
        {userRole === 'admin' && (
          <Button
            variant="outlined"
            startIcon={<CreateNewFolderOutlinedIcon />}
            onClick={() => handleToggleCreateFolderModal()}
          >
            {t('page.knowledgeBase.admin.header.buttons.secondary.label', {
              defaultValue: 'Create a folder',
            })}
          </Button>
        )}
        {!areResourcesLoading && !areResourcesEmpty && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleToggleAddKnowledgeModal()}
          >
            {t('page.knowledgeBase.admin.header.buttons.primary.label', {
              defaultValue: 'Add knowledge',
            })}
          </Button>
        )}
      </Stack>
    </StyledStack>
  );
}
