import { useState } from 'react';

export default function useLocalStorage<T>(key: string, initialValue?: string) {
  const [value, setValue] = useState<T>(() => {
    let storedValue;

    try {
      storedValue = JSON.parse(localStorage.getItem(key)!);
    } catch (e) {
      /* empty */
    }

    if (!storedValue) {
      localStorage.setItem(key, JSON.stringify(initialValue));
      return initialValue;
    }

    return storedValue;
  });

  function setValueAndLocalStorage(newValue: T) {
    setValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
  }

  return {
    value,
    setValue: setValueAndLocalStorage,
  };
}
