import {
  //StyledImg, //TODO:
  StyledStack,
} from '@components/pages/assistants-session/welcome-message/styles';
import useGetOneAssistant from '@components/pages/hooks/useGetOneAssistantByBotId';
import useGetOneThread from '@components/pages/hooks/useGetOneThread';
import { Skeleton, Stack, Typography } from '@mui/material';

export default function WelcomeMessage() {
  const { oneThreadData } = useGetOneThread();

  const {
    oneAssistantData,
    isGetOneAssistantLoading,
    isGetOneAssistantFetching,
  } = useGetOneAssistant(oneThreadData?.botId);
  const isGetOneAssistantBusy =
    isGetOneAssistantLoading || isGetOneAssistantFetching;

  return (
    <StyledStack
      direction="row"
      sx={{
        backgroundColor: (theme) => theme.palette.background.card.light,
      }}
      gap={5}
    >
      {/* {assistantData && assistantData.image && (
        <StyledImg src={assistantData.image} alt={assistantData.name} />
      )} */}
      <Stack sx={{ width: '100%' }}>
        {isGetOneAssistantBusy ? (
          <Skeleton variant="text" height={40} width={120} />
        ) : (
          <Typography variant="h4" component="h1">
            {oneAssistantData?.name}
          </Typography>
        )}
        {isGetOneAssistantBusy ? (
          <Skeleton variant="text" sx={{ width: '100%' }} />
        ) : (
          <Typography variant="body2">
            {oneAssistantData?.description}
          </Typography>
        )}
      </Stack>
    </StyledStack>
  );
}
