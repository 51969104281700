import { UserRoleType } from '@providers/WorkspaceProvider';

export const ROLES: { [key: string]: UserRoleType[] } = {
  HOME: ['individual', 'member'],
  SEARCH: ['individual', 'member'],
  SIDE_KICK: ['individual', 'member', 'admin'],
  ASSISTANTS: ['individual', 'member', 'admin'],
  HISTORY: ['individual', 'member'],
  COMMANDS: ['admin'],
  COMMANDS_EDITOR: ['admin'],
  CONNECTORS: ['admin'],
  USERS: ['admin'],
  KNOWLEDGE_BASE: ['admin', 'member'],
  GET_APP: ['individual', 'member', 'admin'],
  SSO: ['admin'],
};

export const ROLES_BY_PATHNAME = {
  '/': ROLES.HOME,
  '/app/search': ROLES.SEARCH,
  '/app/side-kick': ROLES.SIDE_KICK,
  '/app/side-kick/session': ROLES.SIDE_KICK,
  '/app/assistants': ROLES.ASSISTANTS,
  '/app/assistants/session': ROLES.ASSISTANTS,
  '/app/history': ROLES.HISTORY,
  '/app/commands': ROLES.COMMANDS,
  '/app/commands/editor/*': ROLES.COMMANDS_EDITOR,
  '/app/settings/connectors': ROLES.CONNECTORS,
  '/app/settings/knowledge-base': ROLES.KNOWLEDGE_BASE,
  '/get-the-app': ROLES.GET_APP,
  '/app/workspace/users': ROLES.USERS,
  '/app/settings/sso/*': ROLES.SSO,
} as const;
