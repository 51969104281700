const STATUS = ['Collected', 'Invited', 'Accepted', 'Removed'];

const TABLE_HEADER = [
  'select',
  'User ID',
  'Name',
  'Invite Date',
  'Status',
  'Admin',
] as const;

const PAGINATION = {
  LIMIT: 10,
};

const CONFIG = {
  STATUS,
  TABLE_HEADER,
  PAGINATION,
} as const;

export default CONFIG;
