//TODO: we might need to consider lazy loading the knowledge base component
import AdminKnowledgeBase from '@components/pages/knowledge-base/admin';
import MemberKnowledgeBase from '@components/pages/knowledge-base/member';
import { StyledContainerForFixedHeader } from '@components/pages/styles';
import useLocalStorage from '@hooks/useLocalStorage';
import { UserRoleType } from '@providers/WorkspaceProvider';

const KNOWLEDGE_BASE_TYPES: {
  [key in Exclude<UserRoleType, 'individual'>]: JSX.Element;
} = {
  admin: <AdminKnowledgeBase />,
  member: <MemberKnowledgeBase />,
};

export default function KnowledgeBase() {
  const { value: role } = useLocalStorage<UserRoleType>('role');
  if (role === 'individual') return null;

  return (
    <StyledContainerForFixedHeader
      disableGutters
      maxWidth={false}
      sx={{ pb: 0 }}
    >
      {KNOWLEDGE_BASE_TYPES[role]}
    </StyledContainerForFixedHeader>
  );
}
