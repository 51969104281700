import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import CreateWorkspace from '@layouts/main/sections/toolbar/sections/workspace/steps/create-workspace';
import InviteMembers from '@layouts/main/sections/toolbar/sections/workspace/steps/invite-members';
import UploadWorkspaceImage from '@layouts/main/sections/toolbar/sections/workspace/steps/upload-workspace-image';

const WORKSPACE_CREATION_JOURNEY = [
  <CreateWorkspace />,
  <UploadWorkspaceImage />,
  <InviteMembers />,
];

export default function ActiveForm() {
  const { activeWorkspaceStep } = useToolbar();

  const validStepIndex = Math.min(
    activeWorkspaceStep,
    WORKSPACE_CREATION_JOURNEY.length - 1,
  );

  return WORKSPACE_CREATION_JOURNEY[validStepIndex];
}
