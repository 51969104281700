/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import {
  SourceDetailsType,
  SourceRequestType,
  SourceType,
} from '@shared-types/sources';

type SharedSourceRequestType = {
  id: number;
};

export const sources = {
  getAll: ({ folder = null, page, limit = 10 }: SourceRequestType) =>
    requests(axiosApiInstance).get<SourceType[]>(
      `/v1/entities/sources?folder=${folder}&page=${page}&limit=${limit}`,
    ),
  deleteOne: ({ id }: SharedSourceRequestType) =>
    requests(axiosApiInstance).delete(`/v1/entities/sources/${id}`),
  getOneById: ({ id }: SharedSourceRequestType) =>
    requests(axiosApiInstance).get<SourceDetailsType>(
      `/v1/entities/sources/${id}`,
    ),
};
