import InvitationForm from '@components/invitation-form';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';

export default function InviteMembers() {
  const { handleToggleWorkspaceModal, handleCustomWorkspaceStep } =
    useToolbar();

  function onSubmitHandler() {
    handleCustomWorkspaceStep(0);
    handleToggleWorkspaceModal(false);
  }

  return <InvitationForm onSubmitHandler={onSubmitHandler} />;
}
