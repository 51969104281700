import { useTranslation } from '@desygner/ui-common-translation';
import useWorkspace from '@hooks/useWorkspace';
import { folders } from '@lib/agent';
import { FolderBodyType } from '@shared-types/folders';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

type QueryType = {
  onSuccess:
    | ((
        data: unknown,
        variables: Pick<FolderBodyType, 'name'>,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined;
  parentFolderId: number | null;
};

export default function useMutateFoldersByParentId({
  onSuccess,
  parentFolderId,
}: QueryType) {
  const { t } = useTranslation();

  const {
    data: folderData,
    isError: isMutationFolderError,
    isPending: isMutationFolderPending,
    mutateAsync: mutateFoldersAsync,
  } = useMutation({
    mutationFn: async ({ name }: Pick<FolderBodyType, 'name'>) => {
      const { data } = await folders.createOne({
        name,
        parent: parentFolderId,
      });

      return data;
    },
    onError: (error) => {
      const data = isAxiosError(error) && error.response?.data;
      if (
        data.status === 400 &&
        data.detail === 'A folder with this name already exists'
      ) {
        toast.error(
          t('response.errors.createFolder.duplicate', {
            defaultValue: 'A folder with this name already exists.',
          }),
        );
        return;
      }
      throw new Error(`An error occurred while creating a folder: ${error}`);
    },
    onSuccess,
  });

  return {
    folderData,
    isMutationFolderError,
    isMutationFolderPending,
    mutateFoldersAsync,
  };
}
