/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import {
  CreateMembershipRequestType,
  CreateMembershipResponseType,
  MembershipType,
} from '@shared-types/memberships';
import { PaginationType } from '@shared-types/pagination';
import { WorkspaceResponseType } from '@shared-types/workspace';
import { SourceConfigurationType } from '@shared-types/sources';

type WithWorkspaceIdType = {
  workspaceId: string;
};

export const memberships = {
  getAll: ({ limit, page }: PaginationType) =>
    requests(axiosApiInstance).get<MembershipType[]>(
      `/v1/entities/memberships?page=${page}&limit=${limit}`,
    ),
  getAllMine: ({ workspaceIds }: { workspaceIds: string[] }) =>
    requests(axiosApiInstance).get<MembershipType[]>(
      '/v1/entities/memberships/me',
      {
        headers: {
          'Knowz-Reading-Owners': workspaceIds.join(','),
        },
      },
    ),
  // TODO: move this elsewhere, as this is not about memberships, it is about workspace
  getOneById: ({ workspaceId }: WithWorkspaceIdType) =>
    requests(axiosApiInstance).get<WorkspaceResponseType>(
      `/v1/entities/workspaces/${workspaceId}`,
    ),
  createInvitation: (body: CreateMembershipRequestType) =>
    requests(axiosApiInstance).post<CreateMembershipResponseType>(
      `/v1/entities/memberships`,
      body,
    ),
  acceptInvitation: ({ membershipId }: { membershipId: string }) =>
    requests(axiosApiInstance).post(
      `/v1/actions/membership/accept-invite/${membershipId}`,
    ),
  createMembershipAliasOnSSO: (body: SourceConfigurationType) =>
    requests(axiosApiInstance).post<SourceConfigurationType>(
      '/v1/actions/membership/access-configuration',
      body,
    ),
};
