import getDataWithPagination from '@components/pages/history/utils/getDataWithPagination';
import useWorkspace from '@hooks/useWorkspace';
import { memberships } from '@lib/agent';
import { PaginationType } from '@shared-types/pagination';
import { useQuery } from '@tanstack/react-query';

const LIMIT = 10;

export default function useGetAllMemberships({
  limit = LIMIT,
  page,
}: PaginationType) {
  const { getNamespacedQueryKey } = useWorkspace();

  const {
    data: allMembershipsData,
    isLoading: areAllMembershipsLoading,
    isError: areAllMembershipsError,
  } = useQuery({
    queryKey: getNamespacedQueryKey('allMemberships', `page: ${page}`),
    queryFn: async () => {
      try {
        const { data, headers } = await memberships.getAll({
          limit,
          page,
        });

        const totalCount = headers['xTotalCount'] as string;

        const dataWithPagination = getDataWithPagination({
          data,
          totalCount,
          pageParam: page,
          limit: LIMIT,
        });

        return dataWithPagination;
      } catch (error) {
        throw new Error(
          `An error occurred while fetching memberships: ${error}`,
        );
      }
    },
  });

  return {
    allMembershipsData,
    areAllMembershipsLoading,
    areAllMembershipsError,
  };
}
