import useGetMembershipByWorkspaceIdLazily from '@hooks/useGetMembership';
import useLocalStorage from '@hooks/useLocalStorage';
import {
  setCreationOwnerHeader,
  setReadingOwnerHeader,
} from '@lib/setRoleSession';
import { queryClient } from '@providers/ReactQueryProvider';
import { WorkspaceResponseType } from '@shared-types/workspace';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type UserRoleType = 'admin' | 'member' | 'individual';

export const WorkspaceContext = createContext<{
  isLoaded: boolean;
  isWorkspace: boolean;
  isWorkspaceAdmin: boolean;
  userRole: UserRoleType;
  workspace: WorkspaceResponseType;
  handleSetWorkspace: (workspace: WorkspaceResponseType | null) => void;
  handleSetUserRole: (userRole: UserRoleType) => void;
  resetWorkspaceToIndividualRole: VoidFunction;
  getNamespacedQueryKey: (
    ...keys: Array<string | number | null>
  ) => Array<string | number | null>;
} | null>(null);

export default function WorkspaceProvider({
  children,
}: React.PropsWithChildren) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isWorkspaceAdmin, setIsWorkspaceAdmin] = useState(false);
  const { value: workspace, setValue: setWorkspace } =
    useLocalStorage<WorkspaceResponseType | null>('workspace');
  const { value: userRole, setValue: setUserRole } =
    useLocalStorage<UserRoleType | null>('role');

  const handleSetWorkspace = useCallback(
    (workspace: WorkspaceResponseType | null): void => {
      setIsLoading(true);
      //TODO: fix this timeout
      setTimeout(() => setWorkspace(workspace));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { membershipData } = useGetMembershipByWorkspaceIdLazily({
    id: workspace?.id,
  });

  const handleSetUserRole = useCallback(
    (userRole: UserRoleType): void => {
      if (!isWorkspaceAdmin) throw new Error('User is not an admin');
      if (!workspace) throw new Error('Role cannot be set outside a workspace');
      if (userRole === 'individual')
        throw new Error('Workspace role cannot be individual');

      setIsLoading(true);
      //TODO: fix this timeout
      setTimeout(() => {
        setUserRole(userRole);
        setReadingOwnerHeader(workspace, membershipData, userRole);
        setCreationOwnerHeader(workspace, membershipData, userRole);
        setIsLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isWorkspaceAdmin, membershipData?.id, workspace?.id],
  );

  useEffect(() => {
    const loadingDone = workspace?.id === membershipData?.workspaceId;

    if (loadingDone) {
      const canBeAdmin = !!membershipData?.permissions?.admin;
      let role: UserRoleType = 'individual';

      if (workspace) {
        const hadAdminRoleSelected = userRole === 'admin';
        role = canBeAdmin && hadAdminRoleSelected ? 'admin' : 'member';
      }

      //? enable queries only when the workspace is loaded
      queryClient.setDefaultOptions({
        queries: {
          enabled: true,
        },
      });

      setUserRole(role);
      setIsWorkspaceAdmin(canBeAdmin);
      setReadingOwnerHeader(workspace, membershipData, role);
      setCreationOwnerHeader(workspace, membershipData, role);
      isLoaded || setIsLoaded(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipData?.id]);

  const getNamespacedQueryKey = useCallback(
    function (
      ...keys: Array<string | number | null>
    ): Array<string | number | null> {
      return [`workspace: ${workspace?.id}`, userRole, ...keys];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const resetWorkspaceToIndividualRole = useCallback(() => {
    setUserRole('individual');
    setWorkspace(null);
    setIsWorkspaceAdmin(false);
    setReadingOwnerHeader(null, null, 'individual');
    setCreationOwnerHeader(null, null, 'individual');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      isWorkspace: !!workspace,
      userRole,
      workspace,
      isLoaded,
      isWorkspaceAdmin,
      handleSetWorkspace,
      handleSetUserRole,
      getNamespacedQueryKey,
      resetWorkspaceToIndividualRole,
    }),
    [
      workspace,
      userRole,
      isLoaded,
      isWorkspaceAdmin,
      handleSetWorkspace,
      handleSetUserRole,
      getNamespacedQueryKey,
      resetWorkspaceToIndividualRole,
    ],
  );

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
}
