import { useTranslation } from '@desygner/ui-common-translation';
import { workspaces } from '@lib/agent';
import { WorkspaceRequestType } from '@shared-types/workspace';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { queryClient } from '@providers/ReactQueryProvider';
import useWorkspace from '@hooks/useWorkspace';

export default function useCreateWorkspace(
  onSuccess:
    | ((
        data: unknown,
        variables: WorkspaceRequestType,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
) {
  const { t } = useTranslation();

  const { workspace } = useWorkspace();

  const {
    isPending: isCreateWorkspacePending,
    mutateAsync: mutateCreateWorkspaceAsync,
  } = useMutation({
    mutationKey: ['createNewWorkspace'],
    mutationFn: async ({ name }: WorkspaceRequestType) => {
      const { data } = await workspaces.createOne({
        name,
      });

      return data;
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 429) {
        toast.error(
          t('response.errors.code.429', {
            defaultValue: 'Too many requests. Please try again later.',
          }),
        );
        return;
      }
      console.error(error);
    },
    onSuccess: (data, variables, context) => {
      const getQuery = queryClient
        .getQueryCache()
        .find({ queryKey: ['workspaces'], exact: false });

      if (getQuery == null) return;

      const workspacesCache = queryClient.getQueryData(
        getQuery.queryKey,
      ) as WorkspaceRequestType[];

      if (getQuery && workspacesCache) {
        workspacesCache.push(data);
        queryClient.setQueryData(getQuery.queryKey, workspacesCache);
      }

      onSuccess && onSuccess(data, variables, context);
    },
  });

  return {
    isCreateWorkspacePending,
    mutateCreateWorkspaceAsync,
  };
}
