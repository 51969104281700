import Dropdown from '@components/dropdown';
import useAuth from '@hooks/useAuth';
import useSearchParamsState from '@hooks/useSearchParamsState';
import useWorkspace from '@hooks/useWorkspace';
import useGetAllWorkspaces from '@layouts/main/sections/toolbar/hooks/useGetAllWorkspaces';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import {
  AVATAR_SIZE,
  StyledAddWorkspaceIcon,
  StyledClickableAvatar,
  StyledStack,
  StyledListItem,
} from '@layouts/main/sections/toolbar/workspace/styles';
import AddIcon from '@mui/icons-material/Add';
import { alpha, PopperPlacementType, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useCallback } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import EllipsisTypography from '@components/ellipsis-typography';
import IconButton from '@mui/material/IconButton';

type WorkspaceType = {
  id: string;
  name: string;
};

const DROPDOWN = {
  mobile: { offset: [0, 0], placement: 'top-end' },
  mini: { offset: [0, 15], placement: 'right' },
  default: { offset: [-4, 8], placement: 'right-start' },
};

type Props = {
  mode: 'default' | 'mini' | 'mobile';
};

const SHARED_PROPS = {
  disablePadding: true,
  disableGutters: true,
};

export default function WorkspaceSelector({ mode }: Props) {
  const theme = useTheme();
  const { handleToggleWorkspaceModal } = useToolbar();
  const { me } = useAuth();
  const { handleSetWorkspace, workspace } = useWorkspace();
  const [, setPathSearchParams] = useSearchParamsState('path', '');

  const {
    refetchWorkspaces,
    isWorkspaceLoading,
    workspaceData,
    hasWorkspaceError,
  } = useGetAllWorkspaces();

  function handleRefetchWorkspaces() {
    workspaceData || refetchWorkspaces();
  }

  function handleSelectWorkspace(
    { id: workspaceId }: Pick<WorkspaceType, 'id'> = { id: '' },
  ) {
    handleSetWorkspace(
      workspaceData?.find(({ id }) => id === workspaceId) || null,
    );
    setPathSearchParams(''); //? go to the root folder when workspace is changed
  }

  const getTrigger = useCallback(
    function () {
      if (mode === 'default') {
        return (
          <StyledStack direction="row" spacing={1}>
            {workspace && (
              <StyledListItem {...SHARED_PROPS}>
                <ListItemButton>
                  <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
                    <StyledClickableAvatar
                      sx={{ pointerEvents: 'none' }}
                      src={workspace.profilePicture || ''}
                    >
                      {workspace.name[0].toUpperCase()}
                    </StyledClickableAvatar>

                    <ListItemText
                      sx={{ m: 0 }}
                      primary={
                        <EllipsisTypography
                          variant="body2"
                          lineClamp={1}
                          title={workspace.name}
                          component="span"
                        >
                          {workspace.name}
                        </EllipsisTypography>
                      }
                      primaryTypographyProps={{
                        variant: 'body2',
                      }}
                    />
                  </Stack>
                </ListItemButton>
              </StyledListItem>
            )}

            {!workspace && me && (
              <StyledListItem {...SHARED_PROPS}>
                <ListItemButton>
                  <ListItemIcon>
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="My Space"
                    primaryTypographyProps={{
                      variant: 'body2',
                    }}
                  />
                </ListItemButton>
              </StyledListItem>
            )}

            {/* TODO: skeleton */}
          </StyledStack>
        );
      }

      if (mode === 'mobile') {
        return (
          <Box>
            {workspace && (
              <StyledClickableAvatar src={workspace.profilePicture || ''}>
                <Box component="span">{workspace.name[0].toUpperCase()}</Box>
              </StyledClickableAvatar>
            )}

            {!workspace && me && (
              <IconButton>
                <HomeOutlinedIcon />
              </IconButton>
            )}

            {/* TODO: skeleton */}
          </Box>
        );
      }

      return (
        <Tooltip
          title="Workspace Selection"
          placement="right"
          arrow
          componentsProps={{
            tooltip: { sx: { marginLeft: '18px!important' } },
          }}
        >
          <Box>
            {workspace && (
              <StyledClickableAvatar src={workspace.profilePicture || ''}>
                <Box component="span">{workspace.name[0].toUpperCase()}</Box>
              </StyledClickableAvatar>
            )}

            {!workspace && me && (
              <StyledClickableAvatar src={me.profile_picture} />
            )}

            {/* TODO: skeleton */}
          </Box>
        </Tooltip>
      );
    },
    [mode, workspace, me],
  );

  return (
    <StyledListItem {...SHARED_PROPS}>
      <Dropdown
        withSearch
        sx={{
          menuItemSx: {
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
            },
          },
          paperSx: {
            padding: 1.5,
          },
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: DROPDOWN[mode].offset,
            },
          },
        ]}
        trigger={{
          onClick: handleRefetchWorkspaces,
          element: getTrigger(),
        }}
        placement={DROPDOWN[mode].placement as PopperPlacementType}
        areOptionsLoading={isWorkspaceLoading}
        haveOptionsError={hasWorkspaceError}
        options={[
          {
            icon: {
              iconStart: (
                <StyledListItem {...SHARED_PROPS} sx={AVATAR_SIZE}>
                  <HomeOutlinedIcon />
                </StyledListItem>
              ),
            },
            text: 'My Space',
            onClick: () => handleSelectWorkspace(),
            value: 'My Space',
          },
          ...(workspaceData
            ? workspaceData.map(({ name, id }) => ({
                text: name,
                value: id,
                onClick: () => handleSelectWorkspace({ id }),
                icon: {
                  iconStart: (
                    <Avatar sx={AVATAR_SIZE}>{name[0].toUpperCase()}</Avatar>
                  ),
                },
              }))
            : []),
          {
            icon: {
              iconStart: (
                <StyledAddWorkspaceIcon sx={AVATAR_SIZE}>
                  <AddIcon />
                </StyledAddWorkspaceIcon>
              ),
            },
            text: 'add workspace',
            onClick: () => handleToggleWorkspaceModal(true),
            value: 'add workspace',
          },
        ]}
      />
    </StyledListItem>
  );
}
