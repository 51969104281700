import { useTranslation } from '@desygner/ui-common-translation';
import StyledButton from '@layouts/main/sections/toolbar/sections/workspace/steps/upload-workspace-image/upload-button/styles';
import Uppy, { Meta } from '@uppy/core';
import { FileInput } from '@uppy/react';

export default function ChooseImage({
  uppy,
}: {
  uppy: Uppy<Meta, Record<string, never>>;
}) {
  const { t } = useTranslation();

  return (
    <StyledButton
      component="label"
      role={undefined}
      variant="outlined"
      tabIndex={-1}
    >
      <FileInput uppy={uppy} />
      {t('page.workspace.createWorkspaceModal.step2.form.file.chooseImage', {
        defaultValue: 'Choose image',
      })}
    </StyledButton>
  );
}
