import ListboxComponent from '@components/autocomplete-with-carousel/listbox';
import { StyledStack } from '@components/pages/connectors/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import EllipsisTypography from '@components/ellipsis-typography';
import {
  StyledListItem,
  StyledListItemImage,
} from '@components/pages/sso/styles';
import Box from '@mui/material/Box';
import { useCreateOneSourceConfiguration } from '@hooks/useSourceConfigurations';
import { useCreateMembershipAliasOnSSO } from '@hooks/useCreateMembershipAliasOnSSO';
import SourceConfigurationsPopup from '@components/pages/connectors/SourceConfigurationsPopup';
import { HashMap } from '@shared-types/utils';
import Dialog from '@mui/material/Dialog';

const ICON_SIZE = 84;
const desygnerUrl = import.meta.env.VITE_DESYGNER_URL || '';
const authUrl = import.meta.env.VITE_KNOWZ_AUTH_URL || '';
const ssoAliases: HashMap<string> = {
  slack: import.meta.env.VITE_KNOWZ_CONNECTOR_SLACK_SSO || '',
  confluence: import.meta.env.VITE_KNOWZ_CONNECTOR_CONFLUENCE_SSO || '',
};

export default function Connectors() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [authWindow, setAuthWindow] = useState<Window | null>(null);
  const [handler, setHandler] = useState<string>('none');
  const { mutateAsync: mutateCreateOneSourceConfigurationAsync } =
    useCreateOneSourceConfiguration();
  const { mutateAsync: mutateCreateMembershipAliasOnSSOAsync } =
    useCreateMembershipAliasOnSSO();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const onMessage = useCallback(
    (event: MessageEvent) => {
      let handled = false;

      if (event.origin === authUrl && handler === 'confluence') {
        const { storeKey } = event.data;
        handled = true;
        mutateCreateMembershipAliasOnSSOAsync({
          handler: handler,
          storeKey,
        }).then(() => {
          window.open(import.meta.env.VITE_KNOWZ_CONNECTOR_CONFLUENCE_APP, '_blank');
        });
      } else if (event.origin === authUrl && handler !== 'none') {
        const { storeKey } = event.data;
        handled = true;
        mutateCreateOneSourceConfigurationAsync({
          handler: handler,
          storeKey,
        });
      }

      if (event.origin === desygnerUrl) {
        const { token, domain } = event.data;
        handled = true;

        if (token && domain) {
          mutateCreateOneSourceConfigurationAsync({
            handler: 'webrand',
            token,
            domain,
          });
        }
      }

      if (handled) {
        authWindow && authWindow.close();
        window.removeEventListener('message', onMessage);
        setAuthWindow(null);
      }
    },
    [authWindow, mutateCreateOneSourceConfigurationAsync],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  const connect = useCallback((handler: string) => {
    setHandler(handler);
    if (handler === 'webrand') {
      setAuthWindow(
        window.open(
          `${desygnerUrl}/integration/knowz/setup`,
          'We Brand Authentication',
          'menubar=1,resizable=1,width=600,height=700',
        ),
      );
    } else if (handler === 'email') {
      setIsPopupOpen(true);
    } else {
      setAuthWindow(
        window.open(
          `${authUrl}/connect/oauth2?alias=${ssoAliases[handler]}&process=store`,
          'Knowz Authentication',
          'menubar=1,resizable=1,width=800,height=800',
        ),
      );
    }
  }, []);

  return (
    <>
      <img
        src="https://assets.knowz.com/assets/svg/side-kick.svg"
        alt="knowz Connectors"
        height="93"
      />

      <Typography component="h1" variant="h3">
        {t('layout.page.connectors.hero.title', {
          defaultValue: 'Bring your knowledge',
        })}
      </Typography>

      <StyledStack gap={5}>
        <TextField
          label={t('page.connectors.search.title', {
            defaultValue: 'Find Connectors',
          })}
          onChange={(event) =>
            setFilter(event.target.value.toLocaleLowerCase())
          }
          variant="outlined"
        />

        <ListboxComponent>
          <StyledListItem
            components={{ Root: 'div' }}
            onClick={(event) => connect('webrand')}
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://s3.amazonaws.com/virginia.webrand.com/virginia/company/5899/logo/original/qqwwqryrrwyqrry/group-7.svg"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisTypography
                variant="subtitle2"
                component="h2"
                lineClamp={2}
              >
                We Brand
              </EllipsisTypography>
            </Box>
          </StyledListItem>

          <StyledListItem
            components={{ Root: 'div' }}
            onClick={(event) => connect('slack')}
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Slack_icon_2019.svg/1024px-Slack_icon_2019.svg.png"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisTypography
                variant="subtitle2"
                component="h2"
                lineClamp={2}
              >
                Slack
              </EllipsisTypography>
            </Box>
          </StyledListItem>
          <StyledListItem
            components={{ Root: 'div' }}
            onClick={(event) => connect('confluence')}
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://www.svgrepo.com/show/353597/confluence.svg"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisTypography
                variant="subtitle2"
                component="h2"
                lineClamp={2}
              >
                Confluence
              </EllipsisTypography>
            </Box>
          </StyledListItem>
          <StyledListItem
            components={{ Root: 'div' }}
            onClick={() => connect('email')}
            data-handler="email"
          >
            <StyledListItemImage
              sx={{ mt: 3 }}
              src="https://cdn0.iconfinder.com/data/icons/apple-apps/100/Apple_Mail-512.png"
              loading="lazy"
              height={ICON_SIZE}
            />

            <Box sx={{ p: 3 }}>
              <EllipsisTypography
                variant="subtitle2"
                component="h2"
                lineClamp={2}
              >
                Email
              </EllipsisTypography>
            </Box>
          </StyledListItem>

          <Dialog open={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
            <SourceConfigurationsPopup
              connect={connect}
            />
          </Dialog>
        </ListboxComponent>
      </StyledStack>
    </>
  );
}
