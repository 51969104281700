import Logo from '@components/logo';
import Modal from '@components/modal';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import {
  StyledIconButton,
  StyledImage,
} from '@components/user-authentication/sections/desktop/modal-stepper/styles';
import useAuth from '@hooks/useAuth';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';

const MODAL_WIDTH = 848;
const MODAL_HEIGHT = 600;

const IMAGE_PLACEHOLDER_WIDTH = 424;
const IMAGE_PLACEHOLDER_HEIGHT = 600;

export default function ModalStepper({ children }: React.PropsWithChildren) {
  const { getQueryParamByKey, removeQueryParamsByKeys } = useQueryParams();
  const actionQueryParam = getQueryParamByKey<'secure-account'>('action');
  const subscriptionQueryParam = getQueryParamByKey<'success'>('subscription');
  const theme = useTheme();
  const { resetForm } = useUserAuth();
  const { handleAttemptedToLogin } = useAuth();
  const { resetWorkspaceToIndividualRole } = useWorkspace();

  function closeModal() {
    resetForm();
    handleAttemptedToLogin(true);
    removeQueryParamsByKeys(['action', 'subscription']);
    resetWorkspaceToIndividualRole();
  }

  return (
    <Modal
      keepMounted
      open={
        actionQueryParam === 'secure-account' ||
        subscriptionQueryParam === 'success'
      }
      width={MODAL_WIDTH}
      height={MODAL_HEIGHT}
    >
      <>
        <StyledIconButton onClick={closeModal}>
          <CloseIcon />
        </StyledIconButton>
        <Stack sx={{ p: 8, width: '100%' }}>
          <Logo />
          {children}
        </Stack>
        <StyledImage
          src={
            theme.palette.mode === 'dark'
              ? 'https://assets.knowz.com/assets/images/login-dark.png'
              : 'https://assets.knowz.com/assets/images/login-light.png'
          }
          alt="placeholder"
          width={IMAGE_PLACEHOLDER_WIDTH}
          height={IMAGE_PLACEHOLDER_HEIGHT}
          loading="lazy"
        />
      </>
      {/* Stepper Component goes here */}
    </Modal>
  );
}
