type calculateFullNameParams = {
  firstName?: string;
  lastName?: string;
};

export default function calculateFullName({
  firstName,
  lastName,
}: calculateFullNameParams) {
  const calculatedFirstName = firstName ? firstName : '-';
  const calculatedLastName = lastName ? lastName : '';

  const fullName = `${calculatedFirstName} ${calculatedLastName}`;

  return fullName;
}
