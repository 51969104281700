import useAuth from '@hooks/useAuth';
import { memberships } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

type getMembershipType = {
  id?: string;
};

export default function useGetMembershipByWorkspaceIdLazily({
  id,
}: getMembershipType) {
  const { isUserAuthenticated, me } = useAuth();

  const { data: membershipData } = useQuery({
    queryKey: [id, me?.uuid],
    queryFn: async () => {
      const { data } = await memberships.getAllMine({
        workspaceIds: [id!],
      });

      return data[0];
    },
    enabled: isUserAuthenticated && !!id,
  });

  return { membershipData };
}
