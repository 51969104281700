import { useTranslation } from '@desygner/ui-common-translation';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import CONFIG from '@layouts/main/sections/toolbar/sections/workspace/steps/upload-workspace-image/config';
import ChooseImage from '@layouts/main/sections/toolbar/sections/workspace/steps/upload-workspace-image/upload-button';
import { storage } from '@lib/agent';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { useUppyEvent } from '@uppy/react';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { useState } from 'react';
import { toast } from 'react-toastify';

const THUMBNAIL_SIZE = 250;

type ContextType = {
  usage: 'profile_picture';
};

export default function UploadWorkspaceImage() {
  const { t } = useTranslation();
  const { handleCustomWorkspaceStep } = useToolbar();
  const [isUploading, setIsUploading] = useState(false);

  const [uppy] = useState(() =>
    new Uppy({
      restrictions: { allowedFileTypes: CONFIG.FILE_TYPES },
      allowMultipleUploadBatches: false,
    })
      .use(ThumbnailGenerator, {
        thumbnailWidth: THUMBNAIL_SIZE,
        thumbnailHeight: THUMBNAIL_SIZE,
      })
      .use(AwsS3, {
        async getUploadParameters(file) {
          const name = file.data.name;
          const size = file.data.size;
          const sizeInKB = size / 1024;
          const type = file.type;

          const { data } = await storage.createPolicy<ContextType>({
            key: name,
            size: sizeInKB,
            type,
            context: {
              usage: 'profile_picture',
            },
          });

          return {
            method: 'PUT',
            fields: [],
            headers: {
              'Content-Type': type,
            },
            url: data.url,
          };
        },
      }),
  );

  const [thumbnailGeneratedResults] = useUppyEvent(uppy, 'thumbnail:generated');

  function handleNext() {
    handleCustomWorkspaceStep(2);
  }

  useUppyEvent(uppy, 'upload-success', () => {
    toast.success(
      t('page.workspace.createWorkspaceModal.step2.form.upload.success', {
        defaultValue: 'Image uploaded successfully',
      }),
    );
    handleNext();
  });

  useUppyEvent(uppy, 'upload-error', () => {
    toast.success(
      t('page.workspace.createWorkspaceModal.step2.form.upload.failure', {
        defaultValue: 'Image uploaded failed for some reason',
      }),
    );
  });

  const [, result] = thumbnailGeneratedResults;

  async function uploadHandler() {
    setIsUploading(true);
    await uppy.upload();
    setIsUploading(false);
  }

  return (
    <Stack gap={6} sx={{ alignItems: 'flex-end' }}>
      <Stack gap={3} sx={{ width: '100%', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="body1">
            {t('page.workspace.createWorkspaceModal.step2.form.file.label', {
              defaultValue: 'Workspace Name',
            })}
          </Typography>
          <Typography variant="body2" color="text.disabled">
            {t(
              'page.workspace.createWorkspaceModal.step2.form.file.description',
              {
                defaultValue: 'Establish the name of your Knowz workspace.',
              },
            )}
          </Typography>
        </Box>

        {result ? (
          <Box
            sx={{
              borderRadius: '50%',
              objectFit: 'cover',
            }}
            component="img"
            src={result}
            alt="preview"
            width={THUMBNAIL_SIZE}
            height={THUMBNAIL_SIZE}
          />
        ) : (
          <Box
            sx={{
              backgroundColor: 'background.secondary',
              width: THUMBNAIL_SIZE,
              height: THUMBNAIL_SIZE,
              borderRadius: '50%',
            }}
          />
        )}

        {result ? (
          <LoadingButton
            disabled={isUploading}
            variant="outlined"
            onClick={uploadHandler}
          >
            {t(
              'page.workspace.createWorkspaceModal.step2.form.file.uploadImage',
              {
                defaultValue: 'Upload image',
              },
            )}
          </LoadingButton>
        ) : (
          <ChooseImage uppy={uppy} />
        )}
      </Stack>
      <LoadingButton variant="contained" onClick={handleNext}>
        {t('page.workspace.createWorkspaceModal.step2.form.button.next', {
          defaultValues: 'Next',
        })}
      </LoadingButton>
    </Stack>
  );
}
