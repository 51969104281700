import AudioProvider from '@providers/AudioProvider';
import AuthProvider from '@providers/AuthProvider';
import ReduxProvider from '@providers/ReduxProvider';
import ThemeSettingsProvider from '@providers/ThemeSettingsProvider';
import WebSocketProvider from '@providers/WebSocketProvider';
import WorkspaceProvider from '@providers/WorkspaceProvider';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PortalProvider from './PortalProvider';
import ReactQueryProvider from './ReactQueryProvider';
import ThemeProvider from './ThemeProvider';
import TranslationProvider from './TranslationProvider';

const SITE_KEY = String(import.meta.env.VITE_RECAPTCHA_KEY);

export default function Providers({ children }: React.PropsWithChildren) {
  return (
    <ReactQueryProvider>
      <ReduxProvider>
        <WebSocketProvider>
          <ThemeSettingsProvider>
            <ThemeProvider>
              <TranslationProvider>
                <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
                  <AuthProvider>
                    <AudioProvider>
                      <WorkspaceProvider>
                        <PortalProvider>{children}</PortalProvider>
                      </WorkspaceProvider>
                    </AudioProvider>
                  </AuthProvider>
                </GoogleReCaptchaProvider>
              </TranslationProvider>
            </ThemeProvider>
          </ThemeSettingsProvider>
        </WebSocketProvider>
      </ReduxProvider>
    </ReactQueryProvider>
  );
}
