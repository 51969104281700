import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';
import ToolbarExpansionButton from '@layouts/main/sections/toolbar/components/expand-button';
import CONFIG from '@layouts/main/sections/toolbar/config';
import CreditUsage from '@layouts/main/sections/toolbar/mini/mobile/sections/credit-usage';
import StyledMobileToolbar from '@layouts/main/sections/toolbar/mini/mobile/styles';
import { StyledListItem } from '@layouts/main/sections/toolbar/mini/styles';
import { StyledNavLink } from '@layouts/main/sections/toolbar/styles';
import WorkspaceSelector from '@layouts/main/sections/toolbar/workspace';
import { convertToRem } from '@lib/fonts';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';

export default function MobileToolbar() {
  const { me } = useAuth();
  const { userRole } = useWorkspace();

  return (
    <StyledMobileToolbar disableGutters>
      <Box sx={{ px: convertToRem(CONFIG.PATH_TO_SHOW_COLLAPSING_BUTTON) }}>
        <List
          disablePadding
          direction="row"
          gap={2}
          component={Stack}
          sx={{
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {(!CONFIG.IS_WORKSPACE_SELECTOR_ALPHA || me?.alpha) && (
            <WorkspaceSelector mode="mobile" />
          )}
          {CONFIG.TOOLBAR_ITEMS.filter(({ isAlpha }) => me?.alpha || !isAlpha)
            .filter(({ roles }) => roles.includes(userRole))
            .map(({ icon, url }, i) => (
              <StyledListItem
                key={i}
                sx={{ mb: 0 }}
                disablePadding
                disableGutters
                component={StyledNavLink}
                to={url}
              >
                <IconButton>{icon}</IconButton>
              </StyledListItem>
            ))}
          <CreditUsage />
        </List>
      </Box>
      <ToolbarExpansionButton />
    </StyledMobileToolbar>
  );
}
