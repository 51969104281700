import StyledTypography from '@components/search-form/genius/tooltip/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Content() {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2}>
        <AutoAwesomeIcon />
        <Typography variant="body1">
          {t('page.home.hero.search.genius.title', {
            defaultValue: 'Genius mode',
          })}
        </Typography>
      </Stack>
      <StyledTypography variant="caption">
        {t('page.home.hero.search.genius.subtitle', {
          defaultValue:
            "Looks like you've discovered a smarter way to search. Sign up now to give it a try!",
        })}
      </StyledTypography>
    </Stack>
  );
}
