import { alpha, styled } from '@mui/material';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import { NavLinkProps, To } from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius * 100,
  padding: 0,
})) as React.ComponentType<
  ListItemProps & Omit<NavLinkProps, 'to'> & { to?: To }
>;

export const StyledAddWorkspaceIcon = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
  color: theme.palette.primary.light,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius * 100,
}));

const WORKSPACE_IMG_SIZE = 30;

export const AVATAR_SIZE = {
  width: WORKSPACE_IMG_SIZE,
  height: WORKSPACE_IMG_SIZE,
};

export const StyledClickableAvatar = styled(Avatar)(({ theme }) => ({
  ...AVATAR_SIZE,
  cursor: 'pointer',
  color: theme.palette.primary.contrastText,

  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
  },

  '& span': {
    marginTop: theme.spacing(1),
  },
}));

export const StyledStack = styled(Stack)(() => ({
  width: '100%',
  justifyContent: 'start',
  alignItems: 'center',
  cursor: 'pointer',
  paddingBottom: 0,
}));
