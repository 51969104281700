import LinksBlock from '@components/pages/search/components/shared-blocks/links';
import { LinksType } from '@shared-types/search-result/types';
import { RootType } from '@state/store';
import { useSelector } from 'react-redux';
import LinksLoadingSkeleton from '@components/pages/search/sections/loading-skeleton/sections/links';

const DISPLAY_LIMIT = 3;
const COLS_IN_MD_SCREEN = 3;
const COLS_IN_XS_SCREEN = 6;
const SPACE_BETWEEN = 4;

export default function StreamingLinks() {
  const link = useSelector<RootType, LinksType>(
    (state) => state.websocket.WSResponse.searchResult.layout.link,
  );

  if (link == null) return null;

  if (link.isLoading) {
    return (
      <LinksLoadingSkeleton
        displayLimit={DISPLAY_LIMIT}
        mdSpace={COLS_IN_MD_SCREEN}
        spaceBetween={SPACE_BETWEEN}
        xsSpace={COLS_IN_XS_SCREEN}
      />
    );
  }

  return <LinksBlock links={link.links} />;
}
