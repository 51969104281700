import { styled } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  minHeight: theme.spacing(8.75),
  height: theme.spacing(8.75),
  padding: theme.spacing(0, 0.75),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(3),
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  height: theme.spacing(8.75),
  minHeight: theme.spacing(8.75),
  borderColor: 'red',
  zIndex: 1,
  '&.Mui-selected': {
    color: theme.palette.text.secondary,
  },
}));
