//TODO: useLogin name does not show which type of auth we are using. It should be useEmailAuthLogin
import { useTranslation } from '@desygner/ui-common-translation';
import useWorkspace from '@hooks/useWorkspace';
import { auth } from '@lib/agent';
import { LoginBodyType } from '@shared-types/auth';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const login = async (data: LoginBodyType) => await auth.login(data);

export default function useLogin() {
  const { t } = useTranslation();

  const { resetWorkspaceToIndividualRole } = useWorkspace();

  return useMutation({
    mutationFn: login,
    onSuccess: () => {
      resetWorkspaceToIndividualRole();
      toast.success(
        t('response.success.login', {
          defaultValue: 'You have successfully logged in.',
        }),
      );
    },
  });
}
