import { sourceConfigurations } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';
import useAuth from '@hooks/useAuth';

export default function useGetAllSourceConfigurations() {
  const { isUserAuthenticated } = useAuth();

  const {
    data: sourceConfigs,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['sourceConfigurations'],
    queryFn: async () => {
      const { data } = await sourceConfigurations.getAllSourceConfigurations();
      return data;
    },
    enabled: isUserAuthenticated,
  });

  return { sourceConfigs, isLoading, isError, refetch };
}
