import emailSchema from '@components/invitation-form/schema';
import FormProvider from '@components/react-hook-form/FormProvider';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import { useTranslation } from '@desygner/ui-common-translation';
import useCreateInvitation from '@hooks/useCreateInvitation';
import AddIcon from '@mui/icons-material/Add';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PQueue from 'p-queue';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const defaultValues = {
  email: '',
};

const MEMBERS_LIMIT_LENGTH = 5;

type Props = {
  onSubmitHandler?: VoidFunction; //TODO: change onSubmitHandler name to something more descriptive
};

type MemberToInviteType = Array<{
  status: 'error' | 'success' | 'default';
  email: string;
}>;

const pQ = new PQueue({ concurrency: 5 });

export default function InvitationForm({ onSubmitHandler }: Props) {
  const { t } = useTranslation();

  const [membersToInvite, setMembersToInvite] = useState<MemberToInviteType>(
    [],
  );
  const [formState, setFormState] = useState<'submitting' | 'submitted' | null>(
    null,
  );

  const methods = useForm({
    defaultValues,
  });

  const { createInvitationAsync } = useCreateInvitation();

  const { getValues, setError, resetField, handleSubmit } = methods;

  async function handleSetMembersToInvite() {
    const emailField = getValues('email');

    const isEmailValid = await emailSchema.isValid({
      email: emailField,
    });

    if (emailField.length === 0) {
      return setError('email', {
        type: 'required',
        message: t(
          'page.workspace.createWorkspaceModal.step3.form.email.required',
          {
            defaultValue: 'Email is required',
          },
        ),
      });
    }

    if (!isEmailValid) {
      return setError('email', {
        type: 'validate',
        message: t(
          'page.workspace.createWorkspaceModal.step3.form.email.invalid',
          {
            defaultValue: 'Invalid email',
          },
        ),
      });
    }

    if (
      emailField &&
      membersToInvite.some((member) => member.email === emailField)
    ) {
      return setError('email', {
        type: 'validate',
        message: t(
          'page.workspace.createWorkspaceModal.step3.form.email.duplication',
          {
            defaultValue: 'This email is already in the list',
          },
        ),
      });
    }

    if (membersToInvite.length >= MEMBERS_LIMIT_LENGTH) {
      setError('email', {
        type: 'max',
        message: t('page.workspace.createWorkspaceModal.step3.form.email.max', {
          defaultValue: 'You can only add emails up to 5 members at a time',
        }),
      });
      return;
    }

    setMembersToInvite((prevMembers: MemberToInviteType) => {
      return [...prevMembers, { email: emailField, status: 'default' }];
    });

    resetField('email');
  }

  function handleDelete(email: string) {
    setMembersToInvite(membersToInvite.filter((m) => m.email !== email));
  }

  useEffect(() => {
    setFormState(null);
  }, [membersToInvite.length]);

  function handleSetMembersToInviteOnEnter(
    e: React.KeyboardEvent<HTMLDivElement>,
  ) {
    if (e.key === 'Enter') handleSetMembersToInvite();
  }

  async function onSubmit() {
    setFormState('submitting');
    if (membersToInvite.length > 0) {
      membersToInvite.forEach((member, index) => {
        pQ.add(
          async () =>
            await createInvitationAsync({ email: member.email })
              .then(() => {
                setMembersToInvite((prevMembers) => {
                  return prevMembers.map((m, i) => {
                    if (i === index) {
                      return { ...m, status: 'success' };
                    }
                    return m;
                  });
                });
              })
              .catch((error) => {
                setMembersToInvite((prevMembers) => {
                  return prevMembers.map((m, i) => {
                    if (i === index) {
                      return { ...m, status: 'error' };
                    }
                    return m;
                  });
                });
                console.error(error);
              }),
        );
      });

      pQ.on('idle', () => {
        setFormState('submitted');
      });
    }
  }

  return (
    <FormProvider methods={methods}>
      <Stack gap={6} sx={{ alignItems: 'flex-end' }}>
        <Stack gap={3} sx={{ width: '100%', alignItems: 'center' }}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body1">
              {t('page.workspace.createWorkspaceModal.step3.form.email.label', {
                defaultValue: 'Workspace Name',
              })}
            </Typography>
            <Typography variant="body2" color="text.disabled">
              {t('page.workspace.createWorkspaceModal.step3.form.description', {
                defaultValue: 'Invite your team members through email',
              })}
            </Typography>
          </Box>
          <Stack
            gap={2}
            direction="row"
            sx={{ flexWrap: 'wrap', justifyContent: 'center' }}
          >
            {membersToInvite.map((member) => (
              <Chip
                disabled={formState === 'submitting'}
                color={member.status}
                key={member.email}
                label={member.email}
                onDelete={() => handleDelete(member.email)}
              />
            ))}
          </Stack>
          <RHFTextField
            name="email"
            type="text"
            size="small"
            onKeyDown={handleSetMembersToInviteOnEnter}
            placeholder={t(
              'page.workspace.createWorkspaceModal.step3.form.email.placeholder',
              {
                defaultValue: 'Ex: member@gmail.com',
              },
            )}
            InputProps={{
              sx: {
                p: 0,
              },
              endAdornment: (
                <IconButton
                  color="inherit"
                  onClick={() => handleSetMembersToInvite()}
                >
                  <AddIcon />
                </IconButton>
              ),
            }}
          />
        </Stack>
        {formState === 'submitted' || membersToInvite.length === 0 ? (
          <Button variant="contained" onClick={onSubmitHandler}>
            {t('page.workspace.createWorkspaceModal.step3.form.button.done', {
              defaultValues: 'Done',
            })}
          </Button>
        ) : (
          <LoadingButton
            disabled={formState === 'submitting'}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t('page.workspace.createWorkspaceModal.step3.form.button.send', {
              defaultValues: 'Send',
            })}
          </LoadingButton>
        )}
      </Stack>
    </FormProvider>
  );
}
