import Logo from '@components/logo';
import Modal from '@components/modal';
import StyledStack from '@components/user-authentication/sections/mobile/modal-stepper/styles';
import useQueryParams from '@hooks/useQueryParams';
import Stack from '@mui/material/Stack';

export default function ModalStepper({ children }: React.PropsWithChildren) {
  const { getQueryParamByKey } = useQueryParams();

  const actionQueryParam = getQueryParamByKey<'secure-account'>('action');

  const subscriptionQueryParam = getQueryParamByKey<'success'>('subscription');

  return (
    <Modal
      open={
        actionQueryParam === 'secure-account' ||
        subscriptionQueryParam === 'success'
      }
      width="100%"
      height="100%"
      closeAfterTransition
    >
      <StyledStack direction="row">
        <Stack sx={{ p: 4, width: '100%' }}>
          <Logo />
          {children}
        </Stack>
        {/* Stepper Component goes here */}
      </StyledStack>
    </Modal>
  );
}
