import useResponsive from '@hooks/useResponsive';
import ToolbarProvider from '@layouts/main/sections/toolbar/providers/ToolbarProvider';

export default function Toolbar({ children }: React.PropsWithChildren) {
  const isItDesktop = useResponsive('up', 'md');

  return (
    <ToolbarProvider>
      {isItDesktop ? (
        <>
          <ToolbarProvider.MiniToolbar />
          <ToolbarProvider.ExpandedToolbar />
        </>
      ) : (
        <ToolbarProvider.MobileToolbar />
      )}
      <ToolbarProvider.Content>{children}</ToolbarProvider.Content>
      <ToolbarProvider.ModalStepper>
        <ToolbarProvider.ActiveForm />
      </ToolbarProvider.ModalStepper>
    </ToolbarProvider>
  );
}
