/* eslint-disable no-case-declarations */
import { threads } from '@lib/agent';
import { queryClient } from '@providers/ReactQueryProvider';
import { PayloadAction } from '@reduxjs/toolkit';
import router from '@router/index';
import { HistoryBlocksDataType } from '@shared-types/search-result/history-blocks';
import askAIFetcher from '@state/middleware/utils/askAiFetcher';
import subscribeToThread from '@state/middleware/utils/subscriber';
import { audioStore } from '@state/middleware/utils/textToSpeechHandler';
import {
  askAIErrored,
  WSTextToSpeechLoaded,
} from '@state/slices/search-result';
import { WSLoaded } from '@state/slices/websocket';
import { RootType } from '@state/store';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Action, Middleware } from 'redux';

const searchResultMiddleware: Middleware = ({ dispatch, getState }) => {
  let subscribeThread: string | null = null;

  return (next) => (action) => {
    const { type } = action as Action<
      | 'searchResult/askAIEmitted'
      | 'searchResult/streaming/finished'
      | 'searchResult/textToSpeechEmitted'
    >;
    switch (type) {
      case 'searchResult/askAIEmitted':
        dispatch(WSLoaded(true));
        const { payload: askAIPayload } = action as PayloadAction<{
          threadId: string;
          prompt: string;
          latlng: number[] | null;
          advanced: boolean;
        }>;

        if (askAIPayload == null) return;

        (async function askAI() {
          try {
            subscribeThread = subscribeToThread(
              askAIPayload.threadId,
              subscribeThread,
            );

            await askAIFetcher({
              type: 'search',
              thread: askAIPayload.threadId,
              prompt: askAIPayload.prompt,
              advanced: askAIPayload.advanced,
              //tts: false, //TODO: waiting for backend to deploy text to speech
              context: {
                lat_lng: askAIPayload.latlng || [0, 0],
                language: navigator.language,
                time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
            });
          } catch (error) {
            if (isAxiosError(error)) {
              dispatch(askAIErrored({ status: error.response!.status }));
              dispatch(WSLoaded(false));
              if (error.response!.status === 429) {
                toast.error('Too many requests. Please try again later.'); //TODO: we can not use useTranslation here because we are in a react component
                return;
              }
              if (error.response!.status === 402) {
                queryClient.invalidateQueries({
                  queryKey: ['credits'],
                });

                return router.navigate('/?action=upgrade-account');
              }
              toast.error(
                'An unexpected error occurred. Please try again later.',
              );
              console.error('Error asking AI', error);
              return;
            }
          }
        })();
        break;
      case 'searchResult/streaming/finished':
        const threadId = new URL(window.location.href).searchParams.get('t');
        const WSResponse = (getState() as RootType).websocket.WSResponse;

        //? revalidate credit usage
        queryClient.invalidateQueries({ queryKey: ['credits'] });

        //? store data in a cache
        if (threadId == null || subscribeThread !== threadId) return;

        queryClient.setQueryData(
          ['historyBlocks', threadId],
          (oldHistoryBlocks: HistoryBlocksDataType) => {
            const newHistoryBlocks =
              oldHistoryBlocks == null ? [] : oldHistoryBlocks;
            const historyBlocksData = newHistoryBlocks;

            const answer = WSResponse.searchResult.layout.answer;
            const photos = WSResponse.searchResult.layout.photo;
            const infos = WSResponse.searchResult.layout.infos;
            const links = WSResponse.searchResult.layout.link;
            const followups = WSResponse.searchResult.followups;

            return [
              ...historyBlocksData,
              {
                followups: followups.followupsData,
                layout: {
                  userQuery: window.history.state.prompt,
                  answer: {
                    response: answer?.text,
                    id: answer?.id,
                  },
                  info:
                    infos && infos.infosData.length > 0
                      ? [
                          {
                            response: infos.infosData[0].text,
                          },
                        ]
                      : [],
                  photos: {
                    photos: photos?.photos || [],
                  },
                  links: links?.links || [],
                },
                tos: WSResponse.searchResult.TOS,
                role: 'assistant',
              },
            ];
          },
        );
        break;
      case 'searchResult/textToSpeechEmitted':
        const { payload: textToSpeechPayload } = action as PayloadAction<{
          id: string;
          text: string;
          threadId: string;
        }>;
        if (textToSpeechPayload == null) return;

        subscribeThread = subscribeToThread(
          textToSpeechPayload.threadId,
          subscribeThread,
        );

        if (audioStore.has(textToSpeechPayload.id)) {
          dispatch({
            type: 'searchResult/audioMetadata',
            payload: { id: textToSpeechPayload.id, type: 'toggle' },
          });
          return;
        }

        (async function convertTextToSpeech() {
          try {
            dispatch(WSTextToSpeechLoaded(true));
            await threads.convertTextToSpeech({
              text: textToSpeechPayload.text,
              componentId: textToSpeechPayload.id,
              thread: subscribeThread,
            });

            dispatch({
              type: 'searchResult/audioMetadata',
              payload: { id: textToSpeechPayload.id, type: 'play' },
            });
          } catch (error) {
            if (isAxiosError(error)) {
              //TODO: handle error
              dispatch(WSTextToSpeechLoaded(false));
              return;
            }
          }
        })();
        break;
    }

    next(action);
  };
};

export default searchResultMiddleware;
