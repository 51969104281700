import { memberships } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useTranslation } from '@desygner/ui-common-translation';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useWorkspace from '@hooks/useWorkspace';
import paths from '@router/paths';

export default function useGetMembershipByWorkspaceId() {
  const { t } = useTranslation();
  const { getNamespacedQueryKey } = useWorkspace();
  const navigateTo = useNavigate();

  const { workspace } = useWorkspace();

  const {
    data: membershipData,
    isLoading: isMembershipDataLoading,
    isError: isMembershipDataError,
  } = useQuery({
    queryKey: getNamespacedQueryKey('membership'),
    queryFn: async () => {
      try {
        const { data } = await memberships.getOneById({
          workspaceId: workspace.id,
        });
        return data;
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(
            t('response.errors.code.403', {
              defaultValue: 'Not allowed to access this resource.',
            }),
          );

          return navigateTo(paths.home.pathname);
        }
        throw new Error(
          `An error occurred while fetching memberships: ${error}`,
        );
      }
    },
  });

  return {
    membershipData,
    isMembershipDataLoading,
    isMembershipDataError,
  };
}
