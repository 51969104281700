import RHFSwitch from '@components/react-hook-form/RHFSwitch';
import Tooltip from '@components/search-form/genius/tooltip';
import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import useQueryParams from '@hooks/useQueryParams';
import { IOSSwitch } from '@layouts/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { searchAdvanced } from '@state/slices/search-result';
import { RootType } from '@state/store';
import { useDispatch, useSelector } from 'react-redux';

export default function Genius() {
  const { t } = useTranslation();
  const { setQueryParam } = useQueryParams();

  const { me } = useAuth();

  const hasSecureAccount =
    me?.auth_type === 'email' || me?.auth_type === 'oauth2';

  const dispatch = useDispatch();

  const advancedSearch = useSelector(
    (state: RootType) => state.searchResult.advancedSearch,
  );

  function handleAdvancedSearch(
    _event: React.SyntheticEvent,
    checked: boolean,
  ) {
    if (!hasSecureAccount) {
      setQueryParam('action', 'secure-account');
      return null;
    }
    dispatch(searchAdvanced(checked));
  }

  return (
    <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
      <Tooltip>
        <Box>
          <RHFSwitch
            SwitchComponent={IOSSwitch}
            name="advanced"
            label={
              <Typography variant="body2" color="textSecondary" sx={{ ml: 2 }}>
                {t('page.home.hero.search.advancedSearch', {
                  defaultValue: 'Genius mode',
                })}
              </Typography>
            }
            onChange={handleAdvancedSearch}
            checked={advancedSearch}
            defaultChecked={advancedSearch}
          />
        </Box>
      </Tooltip>
    </Stack>
  );
}
