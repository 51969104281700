import { sources } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

export default function useGetSourceDetailsByIdLazily({
  id,
}: {
  id: number | null;
}) {
  const {
    data: sourceDetailsData,
    isLoading: isSourceDetailsLoading,
    isError: isSourceDetailsError,
  } = useQuery({
    queryKey: ['source', id],
    queryFn: async () => {
      try {
        const { data } = await sources.getOneById({
          id: id!,
        });

        return data;
      } catch (error: unknown) {
        throw new Error(`An error occurred while fetching sources: ${error}`);
      }
    },
    enabled: Boolean(id),
  });

  return {
    sourceDetailsData,
    isSourceDetailsLoading,
    isSourceDetailsError,
  };
}
