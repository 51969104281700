import {
  StyledAvatar,
  StyledStackedMemberships,
} from '@components/pages/knowledge-base/components/table/styles';
import Typography from '@mui/material/Typography';
import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';
import { CellContext } from '@tanstack/react-table';

const NUMBER_OF_MEMBERSHIPS_TO_SHOW = 3;

export default function Permission({
  getValue,
  row,
}: CellContext<FolderType | SourceType, unknown>) {
  const memberships = getValue<SourceType['memberships']>();
  const isCollected = row.original.isCollected;
  const isItAFolder = 'breadcrumb' in row.original;

  if (isItAFolder || !memberships?.length) return '-';

  return (
    <StyledStackedMemberships isCollected={isCollected} direction="row">
      {memberships
        ?.slice(0, NUMBER_OF_MEMBERSHIPS_TO_SHOW)
        .map((membership, i) => {
          const user = membership.user;
          return (
            <StyledAvatar
              key={i}
              src={user?.profilePicture}
              alt={user?.firstName}
            >
              {user?.firstName[0].toUpperCase()}
            </StyledAvatar>
          );
        })}
      {memberships.length > NUMBER_OF_MEMBERSHIPS_TO_SHOW && (
        <StyledAvatar>
          <Typography variant="caption">
            +{memberships.length - NUMBER_OF_MEMBERSHIPS_TO_SHOW}
          </Typography>
        </StyledAvatar>
      )}
    </StyledStackedMemberships>
  );
}
