import React from 'react';
import { StyledStack } from '@components/pages/connectors/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from '@desygner/ui-common-translation';
import useSourceConfigurationConfig from '@hooks/useSourceConfigurationConfig';
import { useCreateOneSourceConfiguration } from '@hooks/useSourceConfigurations';
import useGetAllSourceConfigurations from '@hooks/useGetAllSourceConfigurations';
import DataLoader from '@components/data-loader';

type SourceConfigurationsPopupProps = {
  connect: (handler: string) => void;
}

const SourceConfigurationsPopup: React.FC<SourceConfigurationsPopupProps> = ({ connect }) => {
  const { t } = useTranslation();
  const { sourceConfigs = [], isLoading, isError, refetch: refetchSourceConfigs } = useGetAllSourceConfigurations();
  const emailConfig = Array.isArray(sourceConfigs) ? sourceConfigs.find(config => config.handler === 'email') : null;
  const { sourceConfig, isLoading: isConfigLoading, isError: isConfigError, refetch } = useSourceConfigurationConfig({
    id: emailConfig?.id || 0,
    handler: emailConfig ? 'email' : undefined
  });
  const { mutateAsync: mutateCreateOneSourceConfigurationAsync } = useCreateOneSourceConfiguration();

  const handleCreate = async (handler: string) => {
    try {
      await mutateCreateOneSourceConfigurationAsync({ handler });
      refetchSourceConfigs();
      refetch();
    } catch (error) {
      console.error('Error creating source configuration:', error);
    }
  };

  return (
    <>
      <DataLoader loading={{ isLoading, description: "Loading..." }} error={{ isError, title: "Error loading the data." }} data={{ emptyMessage: "No email config found", length: sourceConfigs.length }} >
        {sourceConfigs && sourceConfigs.map((config) => (
          <>
            {config.handler === 'email' && sourceConfig?.email ? (
              <Box key={config.id} onClick={() => connect(config.handler)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Typography variant="body2">Email: {sourceConfig.email}</Typography>
              </Box>
            ) : null}
          </>
        ))}
      </DataLoader>
      {(!sourceConfigs || sourceConfigs.length === 0 || !emailConfig) && (
        <Button variant="contained" onClick={() => handleCreate('email')}>
          Create Email Configuration
        </Button>
      )}
    </>
  )
}

export default SourceConfigurationsPopup;

