import { ROLES } from '@lib/roles';
import HistoryIcon from '@mui/icons-material/History';
import HubIcon from '@mui/icons-material/Hub';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SearchIcon from '@mui/icons-material/Search';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import { UserRoleType } from '@providers/WorkspaceProvider';
import paths from '@router/paths';

const DELAY_HIDE_EXPANDED_BUTTON = 2500;

type ToolbarItemType = {
  isAlpha?: boolean;
  roles: UserRoleType[];
  mappedLabel: string;
  defaultLabel: string;
  icon: JSX.Element;
  url: string;
};

const TOOLBAR_ITEMS: ToolbarItemType[] = [
  {
    roles: ROLES.SEARCH,
    mappedLabel: 'layout.toolbar.buttons.search',
    defaultLabel: 'Search',
    icon: <SearchIcon />,
    url: paths.home.pathname,
  },
  {
    isAlpha: true,
    roles: ROLES.SIDE_KICK,
    mappedLabel: 'layout.toolbar.buttons.sideKick',
    defaultLabel: 'Side-kick',
    icon: <SentimentSatisfiedAltIcon />,
    url: paths.sideKick.pathname,
  },
  {
    isAlpha: true,
    roles: ROLES.ASSISTANTS,
    mappedLabel: 'layout.toolbar.buttons.assistants',
    defaultLabel: 'Assistants',
    icon: <SmartToyOutlinedIcon />,
    url: paths.assistants.pathname,
  },
  {
    // TODO: consider removing section and handle in each other section instead
    roles: ROLES.HISTORY,
    mappedLabel: 'layout.toolbar.buttons.history',
    defaultLabel: 'History',
    icon: <HistoryIcon />,
    url: paths.history.pathname,
  },
  {
    isAlpha: true,
    roles: ROLES.COMMANDS,
    mappedLabel: 'layout.toolbar.buttons.commands',
    defaultLabel: 'Commands',
    icon: <KeyboardCommandKeyIcon />,
    url: paths.commands.pathname,
  },
  {
    isAlpha: true,
    roles: ROLES.CONNECTORS,
    mappedLabel: 'layout.toolbar.buttons.connectors',
    defaultLabel: 'Connectors',
    icon: <HubIcon />,
    url: paths.connectors.pathname,
  },
  {
    isAlpha: true,
    roles: ROLES.USERS,
    mappedLabel: 'layout.toolbar.buttons.users',
    icon: <ManageAccountsIcon />,
    defaultLabel: 'Users',
    url: paths.users.pathname,
  },
  {
    isAlpha: true,
    roles: ROLES.KNOWLEDGE_BASE, // TODO: member and individual
    mappedLabel: 'layout.toolbar.buttons.knowledgeBase',
    defaultLabel: 'Knowledge Base',
    icon: <StorageOutlinedIcon />,
    url: paths.knowledgeBase.pathname,
  },
];

const IS_WORKSPACE_SELECTOR_ALPHA = true;

const CONFIG = {
  PATH_TO_SHOW_COLLAPSING_BUTTON: 20,
  TOOLBAR_MINI: {
    DESKTOP: {
      WIDTH: 52,
    },
    MOBILE: {
      HEIGHT: 81,
    },
  },
  CREDIT_USAGE: {
    DESKTOP: {
      WIDTH: 44,
    },
    MOBILE: {
      HEIGHT: 44,
    },
  },
  WIDTH_EXPANDED: 232,
  TOOLBAR_ITEMS,
  IS_WORKSPACE_SELECTOR_ALPHA,
  DELAY_HIDE_EXPANDED_BUTTON,
} as const;

export default CONFIG;
