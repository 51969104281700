import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';
import ToolbarExpansionButton from '@layouts/main/sections/toolbar/components/expand-button';
import {
  default as CONFIG,
  default as CONFIG_TOOLBAR,
} from '@layouts/main/sections/toolbar/config';
import CreditUsage from '@layouts/main/sections/toolbar/expanded/sections/credit-usage';
import {
  StyledExpandedToolbar,
  StyledListItem,
  StyledStickyBox,
} from '@layouts/main/sections/toolbar/expanded/styles';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { StyledListStack } from '@layouts/main/sections/toolbar/mini/desktop/styles';
import Socials from '@layouts/main/sections/toolbar/socials';
import { StyledNavLink } from '@layouts/main/sections/toolbar/styles';
import WorkspaceSelector from '@layouts/main/sections/toolbar/workspace';
import { convertToRem } from '@lib/fonts';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useRef } from 'react';
import Transition from 'react-transition-group/Transition';

const FULL_YEAR = new Date().getFullYear();

export default function ExpandedToolbar() {
  const { t } = useTranslation();
  const nodeRef = useRef(null);
  const { me } = useAuth();
  const { userRole } = useWorkspace();

  const {
    toolbarExpansionStatus,
    handleHideExpandedButton,
    handleDisplayExpandedButton,
    isExpandedButtonDisplayed,
  } = useToolbar();

  return (
    toolbarExpansionStatus === 'on' && (
      <Transition
        nodeRef={nodeRef}
        in={isExpandedButtonDisplayed}
        timeout={CONFIG.DELAY_HIDE_EXPANDED_BUTTON}
      >
        {(state) => (
          <StyledStickyBox
            onMouseEnter={handleDisplayExpandedButton}
            onMouseLeave={handleHideExpandedButton}
            sx={{
              pr: convertToRem(CONFIG.PATH_TO_SHOW_COLLAPSING_BUTTON),
            }}
          >
            <StyledExpandedToolbar disableGutters>
              <Stack
                sx={{
                  height: '100%',
                  justifyContent: 'space-between',
                  overflow: 'auto',
                  width: '100%',
                }}
              >
                <StyledListStack
                  disablePadding
                  component={Stack}
                  direction="column"
                >
                  {(!CONFIG_TOOLBAR.IS_WORKSPACE_SELECTOR_ALPHA ||
                    me?.alpha) && <WorkspaceSelector mode="default" />}
                  {CONFIG_TOOLBAR.TOOLBAR_ITEMS.filter(
                    ({ isAlpha }) => me?.alpha || !isAlpha,
                  )
                    .filter(({ roles }) => roles.includes(userRole))
                    .map(({ defaultLabel, icon, mappedLabel, url }, i) => (
                      <StyledListItem
                        to={url}
                        key={i}
                        component={StyledNavLink}
                        disablePadding
                        disableGutters
                      >
                        <ListItemButton>
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText
                            primary={t(mappedLabel, {
                              defaultValue: defaultLabel,
                            })}
                            primaryTypographyProps={{
                              variant: 'body2',
                            }}
                          />
                        </ListItemButton>
                      </StyledListItem>
                    ))}
                </StyledListStack>
                <Stack gap={2} sx={{ justifyContent: 'center' }}>
                  <Typography
                    variant="caption"
                    component={Box}
                    sx={{ textAlign: 'center' }}
                    color="text.secondary"
                  >
                    {t('page.home.footer.copyright', {
                      defaultValue: 'Copyright',
                    })}
                    &nbsp;© {FULL_YEAR} KnowZ
                  </Typography>
                  <Socials />
                  <CreditUsage />
                </Stack>
              </Stack>
              {state === 'exited' ? null : (
                <ToolbarExpansionButton ref={nodeRef} />
              )}
            </StyledExpandedToolbar>
          </StyledStickyBox>
        )}
      </Transition>
    )
  );
}
