import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';
import {
  StyledTab,
  StyledTabs,
} from '@layouts/main/sections/header/sections/user-role/styles';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { UserRoleType } from '@providers/WorkspaceProvider';
import paths from '@router/paths';
import { useCallback } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';

//TODO: skeleton loader
export default function UserRole() {
  const { userRole, workspace, isWorkspaceAdmin, handleSetUserRole } =
    useWorkspace();

  const navigateTo = useNavigate();

  const { pathname } = useLocation();

  const { stringifiedQueryParams } = useQueryParams();

  const getNavigationPath = useCallback(
    (role: string, currentPath: string): To | null => {
      if (role === 'admin' && currentPath === paths.home.pathname) {
        return {
          pathname: paths.sideKick.pathname,
          search: stringifiedQueryParams,
        };
      }
      if (role === 'member' && currentPath === paths.users.pathname) {
        return {
          pathname: paths.home.pathname,
          search: stringifiedQueryParams,
        };
      }

      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userRole],
  );

  if (!workspace || !isWorkspaceAdmin) return null;

  const tooltipHint =
    userRole === 'admin' ? `${workspace.name} admin` : 'Viewing as a member';

  const tooltipDescription =
    userRole === 'admin'
      ? 'You have full access to your workspace settings. You can manage users, add and remove content to the knowledge base, configure team assistants, and more. What you configure here is to be used by all workspace members.'
      : `You are experiencing ${workspace.name} workspace like a regular user would. You can leverage the workspace's knowledge base, assistants, and more. You can also create assistants for your exclusive usage.`;

  const tooltipCta =
    userRole === 'admin'
      ? 'Switch to member mode to experience the platform as a regular user.'
      : 'Switch to admin mode to access workspace settings.';

  return (
    <Tooltip
      title={
        <Stack gap={2} sx={{ p: 2 }}>
          <Stack direction="row" gap={2}>
            <AutoAwesomeIcon />
            <Typography variant="body1">{tooltipHint}</Typography>
          </Stack>
          <Typography variant="caption">{tooltipDescription}</Typography>
          <Typography variant="caption">{tooltipCta}</Typography>
        </Stack>
      }
      placement="bottom-end"
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ],
      }}
    >
      <StyledTabs
        value={userRole}
        onChange={(_, val: UserRoleType) => {
          handleSetUserRole(val);
          const navigationPath = getNavigationPath(val, pathname);
          if (!navigationPath) return null;
          navigateTo(navigationPath);
        }}
        aria-label={`You are on ${userRole} mode`}
        TabIndicatorProps={{
          style: {
            bottom: '50%',
          },
          sx: {
            height: 'calc(100% - 5px)',
            transform: 'translateY(50%)',
            backgroundColor: 'background.card.light',
            borderRadius: 2,
          },
        }}
      >
        {['admin', 'member'].map((role) => (
          <StyledTab
            disableRipple
            disableFocusRipple
            disableTouchRipple
            value={role}
            key={role}
            label={role}
          />
        ))}
      </StyledTabs>
    </Tooltip>
  );
}
