import useAuth from '@hooks/useAuth';
import useQueryParams from '@hooks/useQueryParams';
import { payments } from '@lib/agent';
import getRefreshToken from '@lib/auth/email';
import { setSession } from '@lib/jwt';
import { useEffect, useState } from 'react';

export default function usePaymentWithStripe() {
  const [isLoading, setIsLoading] = useState(false);

  const { handleUpdateMe } = useAuth();

  const { getQueryParamByKey } = useQueryParams();

  const subscriptionParams = getQueryParamByKey('subscription');

  async function handleRedirectToStripe() {
    setIsLoading(true);

    try {
      //? https://www.abeautifulsite.net/posts/opening-a-new-window-after-an-async-operation/
      const newWindow = window.open('about:blank', '_blank');
      if (newWindow) {
        const response = await payments.getStripePaymentLink();
        newWindow.location.href = response?.data.url;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (subscriptionParams === 'success') {
      (async function runOnSubscriptionSuccess() {
        const response = await getRefreshToken();
        if (response?.data.status === 'Ok') {
          const token = response.data.token;
          setSession(token);
          handleUpdateMe(token);
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionParams === 'success']);

  return {
    handleRedirectToStripe,
    isLoading,
  };
}
