import useAuth from '@hooks/useAuth';
import { workspaces } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

export default function useGetAllWorkspaces() {
  const { me } = useAuth();

  const {
    data: workspaceData,
    isLoading: isWorkspaceLoading,
    isError: hasWorkspaceError,
    refetch: refetchWorkspaces,
    isFetchedAfterMount,
  } = useQuery({
    queryKey: ['workspaces', me?.uuid],
    queryFn: async () => {
      const { data } = await workspaces.getAll();

      return data || [];
    },
    enabled: false,
  });

  return {
    isFetchedAfterMount,
    refetchWorkspaces,
    workspaceData,
    isWorkspaceLoading,
    hasWorkspaceError,
  };
}
