import InvitationForm from '@components/invitation-form';
import Modal from '@components/modal';
import StyledStack from '@components/pages/knowledge-base/components/header/styles';
import SkeletonLoading from '@components/pages/users/header/SkeletonLoading';
import useGetMembershipByWorkspaceId from '@components/pages/users/hooks/useGetMembershipById';
import { useTranslation } from '@desygner/ui-common-translation';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

export default function Header() {
  const { t } = useTranslation();

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const { isMembershipDataLoading, membershipData } =
    useGetMembershipByWorkspaceId();

  function handleCloseModal() {
    setIsInviteModalOpen(!isInviteModalOpen);
  }

  return (
    <StyledStack>
      <Modal
        paddingType="md"
        open={isInviteModalOpen}
        onClose={handleCloseModal}
        width={300}
        height={'auto'}
      >
        <InvitationForm onSubmitHandler={handleCloseModal} />
      </Modal>

      {isMembershipDataLoading ? (
        <SkeletonLoading />
      ) : (
        <Typography variant="h3">{membershipData?.name}</Typography>
      )}
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleCloseModal}
      >
        {t('page.workspace.header.button', {
          defaultValue: 'Invite',
        })}
      </Button>
    </StyledStack>
  );
}
