import { assistants } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

export default function useGetOneAssistantByBotId(id?: number) {
  const {
    data: oneAssistantData,
    isLoading: isGetOneAssistantLoading,
    isFetching: isGetOneAssistantFetching,
  } = useQuery({
    queryKey: ['assistant', id],
    queryFn: async () => {
      try {
        const { data: assistantsData } = await assistants.getOne(String(id));
        return assistantsData;
      } catch (error) {
        console.error(error);
      }
    },
    enabled: !!id,
  });

  return {
    oneAssistantData,
    isGetOneAssistantLoading,
    isGetOneAssistantFetching,
  };
}
