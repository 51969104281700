import FormProvider from '@components/react-hook-form/FormProvider';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import createWorkspaceSchema from '@layouts/main/sections/toolbar/sections/workspace/schema';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useCreateWorkspace from '@layouts/main/sections/toolbar/hooks/useCreateWorkspace';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';

const defaultValues = {
  name: '',
};

export default function CreateWorkspace() {
  const { t } = useTranslation();
  const { handleCustomWorkspaceStep } = useToolbar();
  const { isCreateWorkspacePending, mutateCreateWorkspaceAsync } =
    useCreateWorkspace(() => {
      handleCustomWorkspaceStep(1);
    });

  const methods = useForm({
    resolver: yupResolver(createWorkspaceSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  async function onSubmit(data: { name: string }) {
    await mutateCreateWorkspaceAsync({ name: data.name });
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={6} sx={{ alignItems: 'flex-end' }}>
        <Stack gap={3} sx={{ width: '100%' }}>
          <Box>
            <Typography variant="body1">
              {t('page.workspace.createWorkspaceModal.step1.form.name.label', {
                defaultValue: 'Workspace Name',
              })}
            </Typography>
            <Typography variant="body2" color="text.disabled">
              {t(
                'page.workspace.createWorkspaceModal.step1.form.name.description',
                {
                  defaultValue: 'Establish the name of your Knowz workspace.',
                },
              )}
            </Typography>
          </Box>
          <RHFTextField
            name="name"
            size="small"
            placeholder={t(
              'page.workspace.createWorkspaceModal.step1.form.name.placeholder',
              {
                defaultValue: 'Ex: Marketing',
              },
            )}
          />
        </Stack>
        <LoadingButton
          loading={isCreateWorkspacePending}
          disabled={!isDirty}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {t('page.workspace.createWorkspaceModal.step1.form.button.next', {
            defaultValues: 'Next',
          })}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
