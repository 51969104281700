import EllipsisTypography from '@components/ellipsis-typography';
import Details from '@components/pages/knowledge-base/components/table/action-drawer/panels/details';
import LoadingSkeleton from '@components/pages/knowledge-base/components/table/action-drawer/panels/details/loading-skeleton';
import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
} from '@components/pages/knowledge-base/components/table/action-drawer/styles';
import Wrapper from '@components/pages/knowledge-base/components/table/action-drawer/wrapper';
import useGetSourceDetailsByIdLazily from '@components/pages/knowledge-base/hooks/useGetSourceDetailsByIdLazily';
import { useTranslation } from '@desygner/ui-common-translation';
import calculateFullName from '@lib/calculateFullName';
import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

const DRAWER_WIDTH = 400;

type TabValueType = 'details' | 'summary' | 'permission';

type Props = Omit<DrawerProps, 'children' | 'onClose'> & {
  isDrawerOpenById: number | null;
  onClose: VoidFunction;
};

export default function ActionDrawer({
  onClose,
  isDrawerOpenById,
  ...otherProps
}: Props) {
  const [value, setValue] = useState<TabValueType>('details');

  const { isSourceDetailsError, sourceDetailsData, isSourceDetailsLoading } =
    useGetSourceDetailsByIdLazily({
      id: isDrawerOpenById,
    });

  const { t } = useTranslation();

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: TabValueType,
  ) => {
    setValue(newValue);
  };

  return (
    <Drawer
      {...otherProps}
      open={Boolean(isDrawerOpenById)}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: 'background.card.light',
          width: {
            xs: '100%',
            sm: DRAWER_WIDTH,
          },
        },
      }}
    >
      <Stack
        direction="row"
        sx={{ p: 4, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Stack gap={2}>
          {isSourceDetailsLoading ? (
            <Skeleton width={200} height={45} />
          ) : (
            <Typography variant="subtitle1">
              {sourceDetailsData?.name}
            </Typography>
          )}
        </Stack>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box sx={{ p: 4 }}>
        <TabContext value={value}>
          <StyledTabList
            variant="fullWidth"
            onChange={handleChange}
            aria-label="action drawer tabs"
            TabIndicatorProps={{
              style: {
                bottom: '50%',
              },
              sx: {
                height: 'calc(100% - 5px)',
                transform: 'translateY(50%)',
                backgroundColor: 'background.card.light',
                borderRadius: 2,
              },
            }}
          >
            <StyledTab
              label={t(
                'page.knowledgeBase.admin.header.actionDrawer.tab1.details',
                {
                  defaultValue: 'Details',
                },
              )}
              value="details"
            />
            <StyledTab
              label={t(
                'page.knowledgeBase.admin.header.actionDrawer.tab2.summary',
                {
                  defaultValue: 'Summary',
                },
              )}
              value="summary"
            />
            {/* //TODO: implement permission tab */}
            {/* <StyledTab
              label={t('page.admin.header.actionDrawer.tab3.permission', {
                defaultValue: 'Permission',
              })}
              value="permission"
            /> */}
          </StyledTabList>
          <StyledTabPanel value="details">
            <Wrapper>
              {isSourceDetailsLoading ? (
                <LoadingSkeleton />
              ) : (
                sourceDetailsData && (
                  <Details
                    name={sourceDetailsData.name}
                    size={sourceDetailsData.size}
                    createdAt={sourceDetailsData.createdAt}
                    createdBy={calculateFullName({
                      firstName: sourceDetailsData.createdBy.firstName,
                      lastName: sourceDetailsData.createdBy.lastName,
                    })}
                    format={sourceDetailsData.fileType}
                    location={
                      sourceDetailsData.sourceConfiguration == null
                        ? 'Knowz'
                        : sourceDetailsData.sourceConfiguration.handler
                    }
                    updatedAt={sourceDetailsData.updatedAt}
                  />
                )
              )}
            </Wrapper>
          </StyledTabPanel>
          <StyledTabPanel value="summary">
            <Wrapper>
              <EllipsisTypography variant="body2" lineClamp={27}>
                {sourceDetailsData?.summary.length === 0
                  ? t(
                      'page.knowledgeBase.admin.header.actionDrawer.tab2.placeholder',
                      {
                        defaultValue: 'Waiting for summary',
                      },
                    )
                  : sourceDetailsData?.summary}
              </EllipsisTypography>
            </Wrapper>
          </StyledTabPanel>
          {/* //TODO: implement permission tab */}
          {/* <StyledTabPanel value="permission">
            {t('comingSoon', { defaultValue: 'Coming soon' })}
          </StyledTabPanel> */}
        </TabContext>
      </Box>
    </Drawer>
  );
}
