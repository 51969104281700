import ActionDrawer from '@components/pages/knowledge-base/components/table/action-drawer';
import Action from '@components/pages/knowledge-base/components/table/cells/action';
import Format from '@components/pages/knowledge-base/components/table/cells/format';
import Location from '@components/pages/knowledge-base/components/table/cells/location';
import Name from '@components/pages/knowledge-base/components/table/cells/name';
import Permission from '@components/pages/knowledge-base/components/table/cells/permission';
import SelectCell from '@components/pages/knowledge-base/components/table/cells/select';
import SyncStatus from '@components/pages/knowledge-base/components/table/cells/sync-status';
import TABLE_CONFIG from '@components/pages/knowledge-base/components/table/config';
import SelectHeader from '@components/pages/knowledge-base/components/table/headers/select';
import Skeleton from '@components/pages/knowledge-base/components/table/loading-skeleton';
import {
  StyledMuiTableHeadCell,
  StyledPaper,
} from '@components/pages/knowledge-base/components/table/styles';
import KNOWLEDGE_BASE_CONFIG from '@components/pages/knowledge-base/config';
import useGetFoldersDataById from '@components/pages/knowledge-base/hooks/useGetFoldersDataById';
import useGetSourcesDataByFolderId from '@components/pages/knowledge-base/hooks/useGetSourcesDataByFolderId';
import Pagination from '@components/pagination';
import UppyUpload from '@components/uppy-upload';
import useGetFolderDataByPath from '@hooks/useGetFolderDataByPath';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableSortLabel from '@mui/material/TableSortLabel';
import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';

export default function Table() {
  const { getQueryParamByKey } = useQueryParams();

  const path = getQueryParamByKey('path');

  const {
    folderData,
    isFolderDataError,
    isFolderDataPending,
    isFolderDataFetching,
  } = useGetFolderDataByPath({ path });

  const { foldersData, isFoldersDataError, isFoldersDataPending } =
    useGetFoldersDataById({
      folderId: folderData?.id as number | null,
    });

  const { sourcesData, isSourcesDataError, isSourcesDataPending } =
    useGetSourcesDataByFolderId({
      folderId: folderData?.id as number | null,
      limit: KNOWLEDGE_BASE_CONFIG.PAGINATION.LIMIT,
      page: parseInt(getQueryParamByKey('page', '1')),
    });

  const [openDrawerById, setOpenDrawerById] = useState<number | null>(null);

  const resources = useMemo(
    () => [...(foldersData || []), ...(sourcesData?.data || [])],
    [foldersData, sourcesData],
  );

  const { userRole } = useWorkspace();

  const areResourcesPending =
    isSourcesDataPending || isFoldersDataPending || isFolderDataPending;

  const isResourceEmpty = resources.length === 0;

  const hasResourceError =
    isFolderDataError || isFoldersDataError || isSourcesDataError;

  function handleToggleActionDrawer(id: number | null) {
    setOpenDrawerById(id);
  }

  const columns = useMemo<ColumnDef<FolderType | SourceType>[]>(
    () => [
      {
        id: 'select',
        header: (props) => <SelectHeader {...props} />,
        cell: (props) => <SelectCell {...props} />,
      },
      {
        header: 'Name',
        accessorKey: 'name',
        cell: (props) => <Name {...props} />,
      },
      {
        header: 'Format',
        accessorKey: 'fileType',
        cell: (props) => <Format {...props} />,
      },
      {
        header: 'Location',
        accessorKey: 'source_configuration',
        cell: (props) => <Location {...props} />,
      },
      {
        header: 'Sync Status',
        cell: (props) => <SyncStatus {...props} />,
      },
      {
        header: 'Permission',
        accessorKey: 'memberships',
        cell: (props) => <Permission {...props} />,
      },
      {
        header: 'Action',
        accessorKey: 'action',
        cell: (props) => (
          <Action
            {...props}
            handleToggleActionDrawer={handleToggleActionDrawer}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path, userRole],
  );

  const tableData = useReactTable({
    data: resources,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    enableRowSelection: true,
  });

  if (!areResourcesPending && isResourceEmpty) {
    return <UppyUpload />;
  }

  return (
    <>
      <ActionDrawer
        anchor="right"
        isDrawerOpenById={openDrawerById}
        onClose={() => handleToggleActionDrawer(null)}
      />
      <StyledPaper>
        <MuiTable>
          <MuiTableHead>
            <MuiTableRow>
              {tableData?.getHeaderGroups().map((headerGroup) => {
                return headerGroup.headers.map((header) => {
                  const isItSelectCell = header.id === 'select';
                  const areTheySelectOrActionCell =
                    isItSelectCell || header.id === 'action';
                  return (
                    <StyledMuiTableHeadCell
                      key={header.id}
                      sx={{
                        ...(isItSelectCell && {
                          width: '10px',
                        }),
                      }}
                    >
                      <MuiTableSortLabel
                        active={!areTheySelectOrActionCell}
                        hideSortIcon={areTheySelectOrActionCell}
                        IconComponent={SwapVertIcon}
                        sx={{
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </span>
                      </MuiTableSortLabel>
                    </StyledMuiTableHeadCell>
                  );
                });
              })}
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            {areResourcesPending || isFolderDataFetching ? (
              <Skeleton
                headItems={TABLE_CONFIG.ADMIN_TABLE_HEADER}
                numberOfRows={KNOWLEDGE_BASE_CONFIG.PAGINATION.LIMIT}
              />
            ) : (
              tableData.getRowModel().rows.map((row) => {
                return (
                  <MuiTableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <MuiTableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </MuiTableCell>
                    ))}
                  </MuiTableRow>
                );
              })
            )}
          </MuiTableBody>
        </MuiTable>
      </StyledPaper>
      {sourcesData && sourcesData.meta.pagination.pageCount > 0 && (
        <Pagination pageCount={sourcesData.meta.pagination.pageCount} />
      )}
    </>
  );
}
