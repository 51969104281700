import useWorkspace from '@hooks/useWorkspace';
import { assistants } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

export default function useGetAllAssistants() {
  const { getNamespacedQueryKey } = useWorkspace();

  return useQuery({
    queryKey: getNamespacedQueryKey('assistants'),
    queryFn: async () => {
      try {
        const { data: assistantsData } = await assistants.getAll();

        return assistantsData || [];
      } catch (error) {
        console.error(error);
      }
    },
  });
}
