import DataRenderer from '@components/data-loader';
import { StyledPopper, StyledStack } from '@components/dropdown/styles';
import type {
  DropdownItemProps,
  DropdownOptionsProps,
  OverridePopperComponentProps,
} from '@components/dropdown/types';
import { useTranslation } from '@desygner/ui-common-translation';
import SearchIcon from '@mui/icons-material/Search';
import { SxProps } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, {
  cloneElement,
  forwardRef,
  useMemo,
  useRef,
  useState,
} from 'react';

type DropdownProps = Omit<OverridePopperComponentProps, 'sx'> & {
  withSearch?: boolean;
  areOptionsLoading?: boolean;
  haveOptionsError?: boolean;
  trigger: {
    element: React.ReactNode;
    onClick?: (
      event: React.MouseEventHandler<HTMLButtonElement> | undefined,
    ) => void;
  };
  options: DropdownOptionsProps;
  sx?: {
    menuItemSx?: SxProps;
    popperSx?: SxProps;
    paperSx?: SxProps;
  };
};

/**
 * @description A dropdown component that takes in a trigger element and a list of options to display - NOTE: All refs passed to this component will be forwarded to the root div element
 * @param {React.ReactNode} trigger - The element that will trigger the dropdown
 * @param {DropdownOptionsProps["options"]} options - The options that will be displayed in the dropdown
 * @param {OverridePopperComponentProps} props - The props that will be passed to the Popper component of MUI
 * @param {boolean} withSearch - A boolean that determines if the dropdown should have a search input
 * @param {SxProps} sx - The styles that will be passed to the Popper component
 */
const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      options,
      trigger: Trigger,
      sx,
      areOptionsLoading,
      haveOptionsError,
      withSearch = false,
      ...otherProps
    }: DropdownProps,
    ref,
  ) => {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();

    const [searchText, setSearchText] = useState('');

    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = (
      value: React.MouseEventHandler<HTMLButtonElement> | undefined | boolean,
    ) => {
      if (value == null) return;

      if (typeof value === 'boolean') {
        setOpen(value);
      }

      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }

    function handleListItemOnClick(
      event: React.MouseEvent<HTMLLIElement>,
      item: DropdownItemProps,
    ) {
      item.onClick(event, item.value);
      handleClose(event);
    }

    const displayedOptions = useMemo(
      () => options.filter((option) => containsText(option.text, searchText)),
      [options, searchText],
    );

    return (
      <>
        {cloneElement(Trigger.element as React.ReactElement, {
          ref: anchorRef,
          onClick: (
            event: React.MouseEventHandler<HTMLButtonElement> | undefined,
          ) => {
            handleToggle(event);
            Trigger.onClick && Trigger.onClick(event);
          },
          'aria-haspopup': 'listbox',
        })}
        <StyledPopper
          open={open}
          anchorEl={anchorRef.current}
          ref={ref}
          transition
          aria-expanded={open ? 'true' : 'false'}
          aria-haspopup={open ? 'true' : 'false'}
          sx={sx?.popperSx}
          {...otherProps}
        >
          {({ TransitionProps }) => {
            return (
              <Grow {...TransitionProps}>
                <Paper
                  square
                  sx={{
                    backgroundColor: 'background.card.main',
                    borderRadius: 2,
                    ...sx?.paperSx,
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      disablePadding
                      onKeyDown={handleListKeyDown}
                      sx={{ maxHeight: 250, overflowY: 'auto' }}
                    >
                      {withSearch && (
                        <TextField
                          sx={{ mb: 1.5 }}
                          size="small"
                          placeholder="Type to search..."
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== 'Escape') {
                              // Prevents autoselecting item while typing (default Select behaviour)
                              e.stopPropagation();
                            }
                          }}
                        />
                      )}
                      <DataRenderer
                        data={{
                          emptyMessage: t('page.workspace.dataStatus.empty', {
                            defaultValue: 'No workspaces found',
                          }),
                          length: displayedOptions.length,
                        }}
                        loading={{
                          description: t('page.workspace.dataStatus.loading', {
                            defaultValue: 'loading workspaces',
                          }),
                          isLoading: areOptionsLoading || false,
                        }}
                        error={{
                          isError: haveOptionsError || false,
                          title: t('page.workspace.dataStatus.error', {
                            defaultValue: 'Error loading workspaces, try later',
                          }),
                        }}
                      >
                        {(withSearch ? displayedOptions : options).map(
                          (option, i) => {
                            const hasStartIcon = option.icon?.iconStart;
                            const hasEndIcon = option.icon?.iconEnd;
                            return (
                              <MenuItem
                                key={`${option.text}-${i}`}
                                onClick={(e) =>
                                  handleListItemOnClick(e, option)
                                }
                                role="option"
                                disableRipple
                                color={option.color}
                                sx={sx?.menuItemSx}
                              >
                                <StyledStack direction="row">
                                  {hasStartIcon}
                                  <Typography variant="body2">
                                    {option.text}
                                  </Typography>
                                </StyledStack>
                                {hasEndIcon}
                              </MenuItem>
                            );
                          },
                        )}
                      </DataRenderer>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            );
          }}
        </StyledPopper>
      </>
    );
  },
);

export default Dropdown;

function containsText(text: string, searchText: string) {
  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
}
