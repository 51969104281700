import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import StyledStackHeader from '@layouts/main/sections/toolbar/sections/workspace/modal-stepper/header/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

type Props = {
  title: string;
};

export default function Header({ title }: Props) {
  const { handleToggleWorkspaceModal } = useToolbar();

  return (
    <StyledStackHeader direction="row">
      <Typography variant="body1">{title}</Typography>
      <IconButton onClick={() => handleToggleWorkspaceModal(false)}>
        <CloseIcon />
      </IconButton>
    </StyledStackHeader>
  );
}
