/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import { FolderBodyType, FolderType } from '@shared-types/folders';

export const folders = {
  getAll: ({ parent }: Pick<FolderBodyType, 'parent'> = {}) => {
    const endpoint =
      '/v1/entities/folders' +
      (typeof parent === 'undefined' ? '' : `?parent=${parent}`);

    return requests(axiosApiInstance).get<FolderType[]>(endpoint);
  },
  createOne: (body: FolderBodyType) => {
    return requests(axiosApiInstance).post('/v1/entities/folders', body);
  },
  getOneByPath: ({ path }: { path: string }) =>
    requests(axiosApiInstance).get<FolderType>(
      `/v1/entities/folders/path/${path}`,
    ),
  getOneById: ({ folderId }: { folderId: string }) =>
    requests(axiosApiInstance).get<FolderType>(
      `/v1/entities/folders/${folderId}`,
    ),
  deleteOne: ({ id }: { id: number }) =>
    requests(axiosApiInstance).delete(`/v1/entities/folders/${id}`),
};
