import TooltipContent from '@components/search-form/genius/tooltip/content';
import { StyledTooltip } from '@components/search-form/styles';
import useAuth from '@hooks/useAuth';

type Props = {
  children: JSX.Element;
};

export default function Tooltip({ children }: Props) {
  const { me } = useAuth();

  const hasSecureAccount =
    me?.auth_type === 'email' || me?.auth_type === 'oauth2';

  if (hasSecureAccount) return children;

  return (
    <StyledTooltip placement="top" title={<TooltipContent />}>
      {children}
    </StyledTooltip>
  );
}
