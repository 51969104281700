import useThemeSettings from '@hooks/useThemeSettings';
import { IOSSwitch } from '@layouts/styles';

export default function ThemeMode() {
  const { toggleThemeMode, themeMode } = useThemeSettings();

  function handleThemeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const isThemeModeDark = e.currentTarget.checked ? 'dark' : 'light';
    toggleThemeMode(isThemeModeDark);
  }

  function switchGate() {
    return (
      <IOSSwitch checked={themeMode === 'dark'} onChange={handleThemeChange} />
    );
  }

  return switchGate();
}
