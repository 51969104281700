import { ColorSchema, ThemeType } from '@shared-types/theme';

export default function Alert(theme: ThemeType) {
  const { palette } = theme;
  const isLight = palette.mode === 'light';

  const standardStyle = (color: ColorSchema) => {
    return {
      color: palette[color][isLight ? 'darker' : 'lighter'],
      backgroundColor: palette[color][isLight ? 'lighter' : 'darker'],
      '& .MuiAlert-icon': {
        color: palette[color][isLight ? 'darker' : 'lighter'],
      },
    };
  };

  return {
    MuiAlert: {
      styleOverrides: {
        standardInfo: standardStyle('info'),
        standardSuccess: standardStyle('success'),
        standardWarning: standardStyle('warning'),
        standardError: standardStyle('error'),
      },
    },
  };
}
