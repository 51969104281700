const en = {
  layout: {
    main: {
      sections: {
        header: {
          secureAccount: 'Secure your account',
          navigation: {
            first: 'Enterprise Solutions',
          },
          theme: 'Theme',
        },
        userAccount: {
          createYourAccount: 'Create your account',
          butMoreCredits: 'Buy more credits',
          tos: 'Terms of Service',
        },
      },
    },
    auth: {
      logout: 'Logout',
      mobile: {
        buttons: {
          primary: 'Get Started',
        },
      },
      desktop: {
        buttons: {
          primary: 'Sign Up',
          secondary: 'Login',
        },
        modal: {
          steps: {
            enterEmail: {
              label: 'Get started with email',
              anon: 'Continue without an account',
              description:
                'We’ll check if you have an account, and help create one if you don’t.',
              form: {
                email: {
                  required: 'Email is required',
                  invalid: 'Invalid email',
                  placeholder: 'Email',
                },
                button: 'Continue',
              },
            },
            verifyEmail: {
              label: 'Sign up code',
              description: {
                segment1:
                  'Check your email. We have sent you a temporary code to',
                segment2: 'to continue signing up.',
              },
              form: {
                code: {
                  required: 'Code is required',
                  placeholder: 'Code',
                },
                button: 'Verify',
              },
              resendCode: {
                segment1: 'Didn’t get the code?',
                segment2: 'Resend code',
              },
            },
            setupPassword: {
              label: 'Welcome to Knowz',
              description: 'Create a password which is hard to guess',
              form: {
                subscribe:
                  'I agree to Knowz sending marketing communications about Knowz to me.',
                button: 'Continue',
                password: {
                  minChar: 'Password must be at least 8 characters',
                  required: 'Password is required',
                  label: 'Set a password',
                  placeholder: 'Password',
                },
                TOS: {
                  segment1: 'By signing up, you agree to Knowz’s',
                  segment2: 'Terms of User',
                  segment3: 'and',
                  segment4: 'Privacy Policy',
                },
              },
            },
            setupName: {
              label: ' Welcome to Knowz',
              description: 'Tell us a bit about yourself.',
              form: {
                name: {
                  required: 'Name is required',
                  placeholder: 'Your Name',
                },
                button: 'Create your account',
              },
            },
            enterPassword: {
              label: 'Welcome Back!',
              form: {
                password: {
                  required: 'Password is required',
                  label: 'Enter your password',
                  placeholder: 'Password',
                },
                button: 'login',
              },
            },
          },
        },
      },
    },
    toolbar: {
      buttons: {
        expand: 'Expand Toolbar',
        collapse: 'Collapse Toolbar',
        search: 'Search',
        history: 'History',
        sideKick: 'Side-kick',
        commands: 'Commands',
        assistants: 'Assistants',
        knowledgeBase: 'Knowledge Base',
        users: 'Users',
      },
      buyAICredit: {
        title: 'Top Up Your AI Credits!',
        subtitle: 'Purchase more credits to unlock unlimited AI potential!',
        button: 'Buy more!',
        modal: {
          title: 'Get More AI Credits!',
          subtitle:
            'You’re on the FREE plan. Upgrade now to receive more credits and keep using our powerful AI features!',
          button: 'Upgrade Now!',
        },
      },
      tooltipBuyCredit: {
        title: 'Credits',
        subtitle:
          'Use credits for tools like Smart Search—credits reset every month. Run out? Just grab a payment plan to keep going strong!',
      },
    },
    assistantSession: {
      header: {
        tooltip: {
          history: 'History',
          newThread: 'New Thread',
        },
      },
    },
  },
  page: {
    home: {
      //TODO: we do not have a home page in our app change to search later in separate JIRA Card and PR
      hero: {
        title: {
          segment1: 'Unleashing',
          segment2: 'AI Insights',
        },
        subtitle: 'Search no more, Knowz better',
        overlineButton: 'enterprise knowledge',
        search: {
          genius: {
            title: 'Genius mode',
            subtitle:
              "Looks like you've discovered a smarter way to search. Sign up now to give it a try!",
          },
          placeholder: 'Search for...',
          button: 'Uncover secrets',
          required: 'This field is required',
          advancedSearch: 'Genius mode',
        },
      },
      footer: {
        copyright: 'Copyright',
      },
    },
    sideKick: {
      hero: {
        title: 'Need LIVE help now?',
        button: 'Start Smart Recording',
      },
      modes: {
        title: 'Choose your mode',
        placeholder: 'Search for a mode...',
        noOptions: 'No modes found',
      },
    },
    sideKickSession: {
      stopRecording: 'Stop Recording',
      drawer: {
        feed: {
          heading: 'Feed',
        },
        smartReview: {
          heading: 'Smart Review',
        },
      },
      mic: {
        denied: 'Your mic access denied please allow it first',
        prompt: 'Waiting for permission to access your microphone...',
        support:
          'Browser does not support speech recognition please use chrome',
      },
      confirmLeave: {
        title: 'Leave Recording Session',
        content: 'Are you sure you want to leave recording?',
        no: 'No, I want to continue',
        yes: 'Yes, I want to leave',
      },
      confirmStopRecording: {
        title: 'Stop Recording',
        content: 'Are you sure you want to stop recording?',
        no: 'No, I want to continue',
        yes: 'Yes, I want to stop',
      },
    },
    commands: {
      title: 'Command Center',
      subtitle:
        'Create a reusable workflow with fine-tuned control over the actions that are executed.',
      button: 'Create AI Command',
    },
    ai: {
      defaultIntroMsg:
        'Hello! I am a multilingual document assistant here to help you with any questions you may have regarding the uploaded document. Ask me any questions about this workspace that interest you.',
      threadHistory: {
        button: 'New Conversation',
        deleteConfirmation: {
          title: 'Delete Thread',
          content: 'Are you sure you want to delete this thread?',
          no: "No, I'm good",
          yes: 'Yes, please',
        },
        emptyHistory: 'No threads found.',
      },
      threadArea: {
        form: {
          required: 'This field is required',
          placeholder: 'Ask any question about this workspace...',
        },
      },
    },
    search: {
      sections: {
        answer: 'Answer',
        relatedQuestions: 'Related Questions',
        sources: 'Sources',
      },
    },

    error: {
      title: "Uh oh!  There's a glitch in the matrix.",
      subtitle:
        "We're working to fix it. Check back soon for everything to be back to normal.",
      cta: 'Back to Home',
    },
    history: {
      tabs: {
        search: 'Search',
        transcript: 'Transcript (coming soon)',
      },
    },
    assistants: {
      title: 'Assistants',
      button: 'Add Assistants',
      addNewAssistants: 'Add New Assistants',
      startConversation: 'Start Conversation',
      options: {
        placeholder: 'Search for assistants...',
        noOptions: 'No assistants found',
      },
      drawer: {
        integratedAssistants: {
          title: 'Integrate Assistants',
          subtitle: 'Copy and past this code into your site.',
          embeddedCode: 'Embedded code',
        },
        addAssistants: {
          title: 'Create an assistants',
          form: {
            name: {
              label: 'Name',
              placeholder: 'Assistant Name',
              max: 'Assistant name must be less than 60 characters',
              required: 'Name is required',
            },
            description: {
              label: 'Description',
              placeholder: 'Assistant Description',
              max: 'Assistant description must be less than 255 characters',
              required: 'Description is required',
            },
            fallbackSupport: {
              label:
                'Automatically fallback to public knowledge when the answer can not be found in the provided knowledge.',
            },
            publicDefault: {
              label:
                'Allow the user to enquire public knowledge when the answer can not be found in the provided knowledge.',
            },
            communicationStyles: {
              title: 'Communication style',
              description:
                "Personalis your AI's communication style and response structure to align perfectly with your preferences and needs",
            },
            submitButton: 'Add Assistant',
          },
        },
      },
    },
    assistantsSession: {
      drawer: {
        title: 'Conversations',
      },
      messageField: {
        placeholder: 'Type your message here...',
        required: 'This field is required',
      },
    },
    knowledgeBase: {
      upload: {
        note: 'audio, text and docs, up to',
        browseFiles: 'browse files',
        dropHereOr: 'Drop files here or',
      },
      modals: {
        createFolder: {
          title: 'Create folder',
          form: {
            name: {
              label: 'Folder name',
              placeholder: 'Enter title',
              required: 'Name is required',
              max: 'Folder name must be less than 60 characters',
            },
            buttons: {
              submitButton: 'Save',
              cancelButton: 'Cancel',
            },
          },
        },
      },
      admin: {
        header: {
          title: 'Knowledge Base',
          buttons: {
            primary: {
              label: 'Add knowledge',
            },
            secondary: {
              label: 'Create a folder',
            },
          },
          actionDrawer: {
            tab1: {
              details: 'Details',
              name: 'Name',
              format: 'Format',
              location: 'Location',
              createdBy: 'Created By',
              size: 'Size',
              updatedAt: 'Last Updated',
              createdAt: 'Date Created',
            },
            tab2: {
              summary: 'Summary',
              placeholder: 'Waiting for summary',
            },
            tab3: {
              permission: 'Permission',
            },
          },
        },
        actions: {
          delete: {
            source: {
              title: 'Delete Source',
              content: 'Are you sure you want to delete this source?',
              no: "No, I'm good",
              yes: 'Yes, please',
              toast: 'Source has been deleted successfully.',
            },
            folder: {
              title: 'Delete Folder',
              content: 'Are you sure you want to delete this folder?',
              no: "No, I'm good",
              yes: 'Yes, please',
              toast: {
                success: 'Folder has been deleted successfully.',
                deleteConstraint: 'Folder has got some sources in it.',
              },
            },
          },
        },
      },
      member: {
        membership: {
          invalid: 'You do not have permission to accept this invitation.',
          accepted: 'You have successfully accepted this invitation.',
          alreadyAccepted: 'You have already accepted this invitation.',
        },
      },
    },
    workspace: {
      dataStatus: {
        empty: 'No workspace found',
        loading: 'Loading workspaces...',
        error: 'Error loading workspaces, try later',
      },
      createWorkspaceModal: {
        title: 'Create Workspace',
        optionalLabel: 'Optional',
        step1: {
          form: {
            description: 'Establish the name of your Knowz workspace.',
            name: {
              label: 'Workspace Name',
              placeholder: 'Ex: Marketing',
              required: 'Workspace name is required',
              max: 'Workspace name must be less than 60 characters',
            },
            button: {
              next: 'Next',
            },
          },
        },
        step2: {
          form: {
            description:
              'Make sure your workspace stands out with a personal image.',
            file: {
              label: 'Your workspace image',
              uploadImage: 'Upload image',
              chooseImage: 'Choose image',
            },
            button: {
              next: 'Next',
            },
          },
        },
        step3: {
          form: {
            description: 'Invite your team members through email',
            email: {
              label: 'Invite Team members',
              placeholder: 'Ex: member@gmail.com',
              required: 'Email is required',
              duplication: 'Member is already in the list',
              invalid: 'Invalid email',
              max: 'You can only add emails up to 5 members at a time',
            },
            button: {
              done: 'Done',
              send: 'Send',
            },
          },
        },
      },
      header: {
        button: 'Invite',
      },
    },
  },
  flow_editor: {
    add_option: 'Add more',
    placeholder: {
      sourceConfiguration: 'Select a source handler',
      folder: 'Select a folder',
      source: 'Select data source',
      user: 'Select a user',
      group: 'Select group',
      collection: 'Select collection',
    },
  },
  getTheApp: {
    segment1: 'Get Started with AI:',
    segment2: 'Unlock the Power of Conversation',
  },
  response: {
    errors: {
      code: {
        429: 'Too many requests. Please try again later.',
        400: 'Invalid request. Please try again.',
        500: 'Internal server error. Please try again later.',
        520: 'Unknown error. Please try again later.',
        409: 'User already exists. Please try to login.',
        401: 'Invalid credentials. Please try again.',
        404: 'Resource not found.',
        403: 'Not allowed to access this resource.',
      },
      wrongCodeVerification: 'Invalid code. Please try again.',
      createFolder: {
        duplicate: 'A folder with this name already exists.',
      },
      pages: {
        notFound: {
          title: 'Page not found',
          subtitle: 'The page you are looking for does not exist.',
        },
        unauthorized: {
          title: 'Access Denied. You are not authorized.',
          subtitle:
            'Looks like you do not have permission to access this page.',
        },
        serverError: {
          title: 'Uh oh! There is a glitch in the matrix.',
          subtitle:
            'We are working to fix it. Check back soon for everything to be back to normal.',
        },
      },
    },
    success: {
      resendCode: 'Code sent successfully. Please check your email.',
      signUp: 'You account created successfully.',
      login: 'You have successfully logged in.',
      logout: 'You have successfully logged out.',
      createAssistants: 'You have successfully created an assistant.',
      createFolder: 'You have successfully created a folder.',
      uploadedSource: 'You have successfully uploaded a source.',
      inviteMember: 'Invitation sent successfully.',
      subscription:
        "Thanks for subscribing! Please enter your email and complete register process so you don't mistakenly loose your account and you can access in other computers, and browsers and love us all around",
    },
    credit: {
      stripe: 'Redirecting to checkout...',
      insufficient: 'Insufficient credits to perform this action.',
    },
  },
  emptyState: {
    transcript: 'Transcript will start generating as you are speaking',
    historyThreads: 'No threads found. start a new thread',
  },
  comingSoon: 'Coming Soon!',
  roles: {
    protectedRoutes: {
      title: 'Permission Denied',
      description: 'You do not have permission to access this page',
    },
  },
};

export default en;
