import { MinimalFolderType } from '@components/pages/knowledge-base/admin/types';
import useWorkspace from '@hooks/useWorkspace';
import { folders } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

export default function useGetFoldersDataById({
  folderId = null,
}: {
  folderId: MinimalFolderType['folderId'];
}) {
  const { getNamespacedQueryKey } = useWorkspace();
  const {
    data: foldersData,
    isLoading: isFoldersDataLoading,
    isError: isFoldersDataError,
    isPending: isFoldersDataPending,
  } = useQuery({
    queryKey: getNamespacedQueryKey('folders', folderId),
    queryFn: async () => {
      try {
        const { data } = await folders.getAll({
          parent: folderId,
        });

        return data;
      } catch (error: unknown) {
        throw new Error(`An error occurred while fetching folders: ${error}`);
      }
    },
  });

  return {
    foldersData,
    isFoldersDataLoading,
    isFoldersDataError,
    isFoldersDataPending,
  };
}
