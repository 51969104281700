import { ROLES_BY_PATHNAME } from '@lib/roles';
import { UserRoleType } from '@providers/WorkspaceProvider';
import { LoaderFunctionArgs, matchPath } from 'react-router-dom';

export default async function protectRoutes({ request }: LoaderFunctionArgs) {
  const role = JSON.parse(
    window.localStorage.getItem('role')!,
  ) as UserRoleType | null;

  const url = new URL(request.url);

  const foundPath = Object.keys(ROLES_BY_PATHNAME).find((path) =>
    matchPath(path, url.pathname),
  ) as keyof typeof ROLES_BY_PATHNAME;

  if (!ROLES_BY_PATHNAME[foundPath].includes(role ?? 'individual')) {
    throw new Response('Unauthorized', { status: 401 });
  }

  return 'ok';
}
