import Modal from '@components/modal';
import { useTranslation } from '@desygner/ui-common-translation';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import Header from '@layouts/main/sections/toolbar/sections/workspace/modal-stepper/header';
import StyledStack from '@layouts/main/sections/toolbar/sections/workspace/modal-stepper/styles';
import Divider from '@mui/material/Divider';

export default function ModalStepper({ children }: React.PropsWithChildren) {
  const { t } = useTranslation();

  const { isWorkspaceModalOpen } = useToolbar();

  return (
    <Modal open={isWorkspaceModalOpen} width="auto" height="auto">
      <StyledStack>
        <Header
          title={t('page.workspace.createWorkspaceModal.title', {
            defaultValue: 'Create workspace',
          })}
        />
        <Divider
          sx={{
            marginBlock: 4,
          }}
        />
        {children}
        {/* Stepper Component goes here */}
      </StyledStack>
    </Modal>
  );
}
